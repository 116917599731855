import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';

import './plugins/bootstrap-vue';
import './plugins/font-awesome';
import './plugins/highcharts';
import './plugins/custom-components';
import './plugins/filters';
import './plugins/global-mixin';
import StatusHub from '@/plugins/status-hub';

import Vuelidate from 'vuelidate';

Vue.use(StatusHub);
Vue.use(Vuelidate);

Vue.prototype.$http = axios.create({ baseURL: '/api/' });
Vue.prototype.$httpRoot = axios.create();

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
