<template>
	<div>
		<auth-container v-if="$route.name === 'PlanView'" :page="page">
			<h2 class="mb-4" v-if="!status.hasError">
				<router-link to="/plans" class="text-reset text-decoration-none mr-2">Plans</router-link>
				<font-awesome-icon :icon="['fas', 'angle-right']" class="text-gray-400" />
				{{plan.planNumber}}
			</h2>
			<success-alert ref="reportSubmittedAlert" text="Error report submitted." />

			<div v-if="status.isRunning" class="max-w-400 py-5 mx-auto text-center">
				<b-progress :value="status.progress.percent" :max="100" animated></b-progress>
				<p>
					{{status.progress.message}}
				</p>

				<p class="text-muted mt-5">
					<small>Progress not updating? <a href="#" @click.prevent="get">Refresh now.</a></small>
				</p>
			</div>
			<div v-else-if="status.hasError">
				<h2 class="mb-3">
					<font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="icon mr-1 text-warning" />
					An error occurred while running this plan
				</h2>
				<p>
					Errors can be the result of a bug in the system or due to a change to your SWAT input data that caused a crash in the model.
					If you believe the error is a result of customizations to your input data, simply clear the error, modify the necessary inputs, and run the plan again.
					If you need further assistance with the error, submit an error report.
					Please see the full exception message below.
				</p>

				<hr class="my-4" />

				<h5>{{status.progress.message}}</h5>
				<p>
					<b-form-textarea v-model="status.exceptionMessage" rows="15"></b-form-textarea>
				</p>

				<fixed-action-bar :cols-lg="12" :offset-lg="0" :cols="12" :offset="0" fixed-width>
					<b-button @click="page.errorReport.show = true" class="mr-2">Submit Error Report</b-button>
					<b-button variant="secondary" @click="clearError" class="mr-2">Clear Error</b-button>
				</fixed-action-bar>

				<b-modal v-model="page.errorReport.show" title="Submit an error report">
					<error-list :errors="page.errorReport.errors"></error-list>

					<p>
						Complete the form below to notify developers about the error.
						If you have already submitted a report for this error or a similar error, there is no need to submit again.
					</p>

					<b-form-group label="Add any additional comments (optional)">
						<b-form-textarea v-model="errorForm.userComments" rows="7"></b-form-textarea>
					</b-form-group>

					<div class="mt-4">
						<b-form-checkbox v-model="errorForm.clearError">Clear error after submitting report</b-form-checkbox>
					</div>

					<div slot="modal-footer">
						<save-button type="button" :saving="page.errorReport.saving" @click.native="submitErrorReport" text="Submit Report" variant="success" />
						<b-button type="button" variant="secondary" @click="page.errorReport.show = false" class="ml-1">Cancel</b-button>
					</div>
				</b-modal>
			</div>
			<div v-else>
				<p class="text-muted mb-4 font-italic" v-if="status.hasRun" style="margin-top:-1.5rem">
					<small>Last run {{status.lastRunDate | dateFromNow}} in {{status.runTime}}.</small>
				</p>

				<b-row>
					<b-col md>
						<table class="table table-definitions">
							<tbody>
								<tr>
									<th>Plan number:</th>
									<td>{{plan.planNumber}}</td>
								</tr>
								<tr>
									<th>Producer:</th>
									<td>{{plan.producer}}</td>
								</tr>
								<tr>
									<th>County:</th>
									<td>{{plan.county}}</td>
								</tr>
								<tr v-if="plan.latitude !== 0 || plan.longitude !== 0">
									<th>Location:</th>
									<td>
										{{locationTime}}
										<font-awesome-icon :icon="['fas', 'info-circle']" class="ml-2 text-muted pointer" v-b-tooltip.hover="`${numberFixed(plan.latitude, 5)}, ${numberFixed(plan.longitude, 5)}`" />
									</td>
								</tr>
								<tr v-if="!isNullOrEmpty(plan.huc12)">
									<th>HUC 12:</th>
									<td>{{plan.huc12}}</td>
								</tr>
							</tbody>
						</table>
					</b-col>
					<b-col md>
						<table class="table table-definitions">
							<tbody>
								<tr>
									<th>Plan date:</th>
									<td>{{plan.farmDate | date('ddd, ll')}}</td>
								</tr>
								<tr>
									<th>Changes last saved:</th>
									<td>{{status.lastModified | date}}</td>
								</tr>
								<tr v-if="!isNullOrEmpty(plan.farmEvaluator)">
									<th>Evaluator:</th>
									<td>{{plan.farmEvaluator}}</td>
								</tr>
								<tr v-if="plan.farmFunding319 || plan.farmFunding503 || !isNullOrEmpty(plan.farmFundingOther)">
									<th>Funding:</th>
									<td>
										<b-badge v-if="plan.farmFunding319" class="mr-2">319</b-badge>
										<b-badge v-if="plan.farmFunding503" class="mr-2">503</b-badge>
										<b-badge v-if="plan.farmFundingOther">{{plan.farmFundingOther}}</b-badge>
									</td>
								</tr>
								<tr v-if="!isNullOrEmpty(plan.farmDescription)">
									<th>Description:</th>
									<td>
										<pre class="mb-0 font-body">{{plan.farmDescription}}</pre>
									</td>
								</tr>
							</tbody>
						</table>
					</b-col>
				</b-row>

				<hr class="mt-0 mb-4" />

				<h3 class="mb-3">Fields</h3>

				<table class="table-list mb-3" v-if="fields.length > 0">
					<tbody>
						<template v-for="field in fields">
							<tr :key="'head' + field.id" @click="goToField(field.id)" class="heading">
								<td><b>{{field.name}}</b></td>
								<td>{{getFieldDescription(field)}}</td>
								<td class="text-right font-italic">Last saved {{field.lastModified | date}}</td>
							</tr>
							<tr :key="'imps' + imp.id" class="row-list" v-for="imp in field.implementations" @click="goToImplementation(imp.id, field.id)">
								<td><b>{{imp.description}}</b></td>
								<td>{{imp.cropName}} / {{imp.region}}</td>
								<td class="text-right font-italic">{{imp.lastModified | date}}</td>
							</tr>
							<tr :key="'noimps' + field.id" v-if="field.implementations.length < 1" style="cursor:default">
								<td colspan="3">
									There are no implementations on this field yet.
									<router-link :to="`fields/${field.id}/implementations/create`" append>Create one now.</router-link>
								</td>
							</tr>
						</template>
					</tbody>
				</table>

				<p>
					<b-button variant="info" to="fields/create" append>Create new field</b-button>
				</p>

				<fixed-action-bar :cols-lg="12" :offset-lg="0" :cols="12" :offset="0" fixed-width>
					<b-dropdown v-if="status.hasRun && !isNullOrEmpty(status.zipFile)" variant="success" text="Results" class="mr-2">
						<b-dropdown-item :href="`/data/planreport/${page.id}?access_token=${localStorageToken}`" target="_blank">Conservation practice evaluation report</b-dropdown-item>
						<b-dropdown-item :href="`/data/singlereport/${page.id}?access_token=${localStorageToken}`" target="_blank">Monthly predictions report</b-dropdown-item>
						<b-dropdown-item :href="`/data/csvreport/${page.id}?access_token=${localStorageToken}`" target="_blank">Total reductions CSV file</b-dropdown-item>
						<b-dropdown-item :href="status.zipFile">Download model files</b-dropdown-item>
						<b-dropdown-item @click="page.run.show = true">Re-run plan</b-dropdown-item>
					</b-dropdown>
					<b-button v-else-if="status.isRunnable" type="button" @click="page.run.show = true" :disabled="page.saving" variant="success" class="mr-2">Run plan</b-button>

					<b-button type="button" variant="info" to="edit" append class="mr-2">Edit plan</b-button>
					<parent-button back-to="plans" to="/plans"></parent-button>
					<b-button type="button" @click="page.copy.show = true" :disabled="page.saving" variant="warning" class="ml-auto mr-2">Copy</b-button>
					<b-button variant="danger" @click="page.delete.show = true">Delete</b-button>
				</fixed-action-bar>
			</div>

			<b-modal v-model="page.run.show" title="Run plan">
				<error-list :errors="page.run.errors"></error-list>

				<b-alert variant="warning" :show="status.hasRun">
					Please note the previous run of this plan implementation will be overwritten.
					Be sure to download your files first if you wish to keep them.
				</b-alert>

				<p>
					Click the button below to run the plan.
					This will write your SWAT input files, run the model, and process model outputs for TBET.
					If you still want to change your plan settings, click the cancel button.
				</p>

				<div class="mt-4">
					<b-form-checkbox v-model="page.run.emailMe">Email me when run completes</b-form-checkbox>
				</div>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.run.saving" @click.native="run" text="Run Plan" variant="success" :disabled="page.run.saving || !status.isRunnable" />
					<b-button type="button" variant="secondary" @click="page.run.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>

			<b-modal v-model="page.copy.show" title="Copy plan">
				<error-list :errors="page.copy.errors"></error-list>

				<p>
					Click the button below to copy this plan.
					All fields and implementations will be copied. Run results will not.
				</p>
				<p>
					When copying is complete, you'll be redirected to the page for the new plan.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.copy.saving" @click.native="copy" text="Copy Plan" variant="success" :disabled="page.copy.saving" />
					<b-button type="button" variant="secondary" @click="page.copy.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>

			<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.delete.errors"></error-list>

				<p>
					Are you sure you want to delete this plan?
					This action is permanent and cannot be undone.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import moment from 'moment';

	export default {
		name: 'PlanView',
		data() {
			return {
				page: {
					id: this.$route.params.id,
					errors: [],
					loading: false,
					showLogin: false,
					running: false,
					delete: {
						show: false,
						errors: [],
						saving: false
					},
					hasInitializedHub: false,
					errorReport: {
						show: false,
						errors: [],
						saving: false
					},
					run: {
						show: false,
						errors: [],
						saving: false,
						emailMe: false
					},
					copy: {
						show: false,
						errors: [],
						saving: false
					}
				},
				plan: {},
				fields: [],
				status: {
					isRunning: false,
					hasError: false,
					hasRun: false,
					lastRunDate: null,
					runTime: null,
					zipFile: null,
					progress: {
						percent: 0,
						message: 'Please wait...'
					},
					exceptionMessage: null,
					isRunnable: false
				},
				errorForm: {
					planId: null,
					title: '',
					message: '',
					exception: '',
					userComments: '',
					clearError: true
				},
				table: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'name', sortable: true },
						{ key: 'area', sortable: true, formatter: (value) => { return this.numberWithCommas(value) } },
						{ key: 'lastModified', sortable: true, formatter: (value) => { return moment(value).format('M/D/YYYY h:mm a') } }
					],
					sort: 'lastModified',
					reverse: true,
					itemsPerPage: 10
				}
			}
		},
		async created() {
			this.initHub();
		},
		async mounted() {
			await this.get();
		},
		beforeDestroy() {
			this.destroyHub();
		},
		watch: {
			'$route': 'watchId'
		},
		methods: {
			async watchId() {
				this.page.id = this.$route.params.id;
				this.initHub();
				await this.get();
			},
			initHub() {
				if (!this.page.hasInitializedHub) {
					this.log('init hub for ' + this.page.id);
					this.$statusHub.planOpened(this.page.id);
					this.$statusHub.$on('plan-status-changed', this.onStatusChanged);
					this.page.hasInitializedHub = true;
				}
			},
			destroyHub() {
				if (this.page.hasInitializedHub) {
					this.log('destroy hub for ' + this.page.id);
					this.$statusHub.planClosed(this.page.id);
					this.$statusHub.$off('plan-status-changed', this.onStatusChanged);
				}
			},
			async onStatusChanged(data) {
				if (this.page.id != data.planId) return;
				if (data.progress == 100) {
					await this.get();
				}

				this.status.progress.percent = data.progress;
				this.status.progress.message = data.statusMessage;
			},
			async get() {
				this.page.id = this.$route.params.id;
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`plans/${this.page.id}`, this.getTokenHeader());
					this.log(response.data);

					this.plan = response.data.plan;
					this.status = response.data.status;
					this.fields = response.data.fields;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				if (this.isUpdate && !this.page.hasInitializedHub) {
					this.initHub();
				}

				this.page.loading = false;
			},
			async run() {
				this.page.run.errors = [];
				this.page.run.saving = true;

				try {
					var model = {
						value: this.page.run.emailMe
					};
					await this.$http.post(`plans/run/${this.page.id}`, model, this.getTokenHeader());
					this.status.isRunning = true;
					this.status.progress.percent = 0;
					this.status.progress.message = "In the queue, preparing to run...";
					this.page.run.show = false;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.run.errors = this.logError(error);
				}

				this.page.run.saving = false;
			},
			async copy() {
				this.page.copy.errors = [];
				this.page.copy.saving = true;

				try {
					const response = await this.$http.post(`plans/copy/${this.page.id}`, {}, this.getTokenHeader());
					this.page.copy.show = false;
					this.destroyHub();
					this.page.hasInitializedHub = false;
					this.$router.push({ name: 'PlanEdit', params: { id: response.data.toString() } }).catch(err => { this.log(err); });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.copy.errors = this.logError(error);
				}

				this.page.copy.saving = false;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`plans/${this.page.id}`, this.getTokenHeader());
					this.log(response.data);
					this.$router.push({ name: 'PlanList' }).catch(err => { this.log(err); });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			async clearError() {
				try {
					await this.$http.get(`plans/clearerror/${this.page.id}`, this.getTokenHeader());
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}
			},
			async submitErrorReport() {
				try {
					this.errorForm.planId = Number(this.page.id);
					this.errorForm.exception = this.status.exceptionMessage;
					this.errorForm.title = this.status.progress.message;
					this.errorForm.message = this.status.progress.message;
					await this.$http.post(`errorreports`, this.errorForm, this.getTokenHeader());
					await this.get();
					this.$refs.reportSubmittedAlert.startAlert();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errorReport.errors = this.logError(error);
				}
			},
			goToField(id) {
				this.$router.push({ name: 'FieldView', params: { id: this.page.id, fieldId: id.toString() } }).catch(err => { this.log(err); });
			},
			goToImplementation(id, fieldId) {
				this.$router.push({ name: 'ImplementationEdit', params: { id: this.page.id, fieldId: fieldId.toString(), implementationId: id.toString() } }).catch(err => { this.log(err); });
			},
			getFieldDescription(field) {
				let s = `${field.area} acres`;
				for (let soil of field.soils) {
					s += `, ${soil.fieldPercentage}% ${soil.name}`;
				}
				return s;
			}
		},
		computed: {
			locationTime() {
				/*let deg = Math.floor(this.plan.latitude);
				let min = (this.plan.latitude % 1) * 60;
				let sec = Math.ceil((min % 1) * 60);
				let lat = `${deg}° ${Math.floor(min)}' ${sec}'' N`;

				deg = Math.floor(this.plan.longitude * -1);
				min = ((this.plan.longitude * -1) % 1) * 60;
				sec = Math.ceil((min % 1) * 60);
				let lon = `${deg}° ${Math.floor(min)}' ${sec}'' W`;*/

				let latObj = this.convertDdToDms(this.plan.latitude);
				let lonObj = this.convertDdToDms(this.plan.longitude);

				let lat = `${latObj.deg}° ${latObj.min}' ${latObj.sec}'' N`;
				let lon = `${lonObj.deg}° ${lonObj.min}' ${lonObj.sec}'' N`;

				return `${lat}, ${lon}`;
			}
		}
	}
</script>