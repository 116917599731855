import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
		token: localStorage.getItem('auth_token') || '',
		user: {
			userName: undefined,
			email: undefined,
			firstName: undefined,
			lastName: undefined,
			emailConfirmed: undefined,
			token: undefined,
			roles: []
		}
    },
	mutations: {
		login(state, payload) {
			state.token = payload.token;
			state.user = payload.user;
		},
		logout(state) {
			state.token = '';
			state.user = {
				email: undefined,
				firstName: undefined,
				lastName: undefined,
				emailConfirmed: undefined,
				token: undefined,
				roles: []
			};
		}
    },
	getters: {
		isAuthenticated: state => !!state.token,
		user: state => state.user,
		roles: state => state.user.roles,
		token: state => state.token
	}
})
