<template>
	<auth-container :page="page">
		<h2 class="mb-4">
			<router-link to="/plans" class="text-reset text-decoration-none mr-2">Plans</router-link>
			<font-awesome-icon :icon="['fas', 'angle-right']" class="text-gray-400 mr-2" />
			<router-link :to="`/plans/${planId}`" class="text-reset text-decoration-none mr-2">{{planNumber}}</router-link>
			<font-awesome-icon :icon="['fas', 'angle-right']" class="text-gray-400" />
			<router-link :to="`/plans/${planId}/fields/${fieldId}`" class="text-reset text-decoration-none mr-2 ml-2">{{fieldName}}</router-link>
			<font-awesome-icon :icon="['fas', 'angle-right']" class="text-gray-400" />
			{{isUpdate ? implementationName : 'New implementation'}}
		</h2>
		<error-list :errors="page.saveErrors"></error-list>

		<b-toast id="changes-saved" variant="success" solid title="Changes saved" toaster="b-toaster-top-center">
			Your changes have been saved.
		</b-toast>

		<div>
			<b-form @submit.prevent="save">
				<div class="position-relative">
					<div style="right:0" class="position-absolute bg-white p-1 d-xl-none">
						<b-button size="sm" variant="light" @click="scrollLeft(100)" class="border mr-1" title="Scroll tabs left"><font-awesome-icon icon="chevron-left" /></b-button>
						<b-button size="sm" variant="light" @click="scrollRight(100)" class="border" title="Scroll tabs right"><font-awesome-icon icon="chevron-right" /></b-button>
					</div>

					<b-tabs v-model="tabs.index" content-class="mt-4" class="page-nav-tabs tabs-scroll">
						<b-tab title="Info">
							<b-form-group label="Implementation status" :invalid-feedback="requiredFeedback($v.form.implementationStatus)">
								<b-form-select disabled v-model.trim="$v.form.implementationStatus.$model" :options="options.statuses" :state="getValidState($v.form.implementationStatus)"></b-form-select>
							</b-form-group>

							<b-row>
								<b-col md>
									<b-form-group label="Bare soil (acres)" :invalid-feedback="requiredFeedback($v.form.bareSoil)">
										<b-form-input v-model.number.trim="$v.form.bareSoil.$model" type="number" step="any" :state="getValidState($v.form.bareSoil)"></b-form-input>
									</b-form-group>
								</b-col>
								<b-col md>
									<b-form-group label="Soil test phosphorus (ppm)" :invalid-feedback="requiredFeedback($v.form.soilTestP)">
										<b-input-group>
											<b-form-input v-model.number.trim="$v.form.soilTestP.$model" type="number" step="any" :state="getValidState($v.form.soilTestP)"></b-form-input>
											<b-input-group-append>
												<b-button v-b-modal.soilTestPModal variant="info">STP Estimation</b-button>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>

							<b-modal id="soilTestPModal" title="Soil Test P Estimation" hide-footer size="lg">
								<img :src="`${publicPath}images/soilTestP.jpg`" alt="Soil Test P Estimation Graphic" class="img-fluid" />
							</b-modal>
						</b-tab>
						<b-tab title="Management">
							<b-row>
								<b-col md="7">
									<b-form-group label="Crop system">
										<b-form-select v-model="mgtSelections.cropSystem" :options="options.cropSystems" @change="loadNav('cropName')">
											<template v-slot:first>
												<b-form-select-option :value="null" disabled>-- Select a crop system --</b-form-select-option>
											</template>
										</b-form-select>
									</b-form-group>

									<error-list :errors="page.mgtNav.cropName.errors"></error-list>
									<font-awesome-icon v-if="page.mgtNav.cropName.loading" :icon="['fas', 'spinner']" spin class="my-3" />
									<b-form-group v-else-if="mgtNavOptions.cropName.length > 0" label="Crop name">
										<b-form-select v-model="mgtSelections.cropName" :options="mgtNavOptions.cropName" @change="loadNav('region')">
											<template v-slot:first>
												<b-form-select-option :value="null" disabled>-- Select a crop --</b-form-select-option>
											</template>
										</b-form-select>
									</b-form-group>

									<error-list :errors="page.mgtNav.region.errors"></error-list>
									<font-awesome-icon v-if="page.mgtNav.region.loading" :icon="['fas', 'spinner']" spin class="my-3" />
									<b-form-group v-else-if="mgtNavOptions.region.length > 0" label="Region">
										<b-form-select v-model="mgtSelections.region" :options="mgtNavOptions.region" @change="loadNav('nav4')">
											<template v-slot:first>
												<b-form-select-option :value="null" disabled>-- Select a region --</b-form-select-option>
											</template>
										</b-form-select>
									</b-form-group>

									<error-list :errors="page.mgtNav.nav4.errors"></error-list>
									<font-awesome-icon v-if="page.mgtNav.nav4.loading" :icon="['fas', 'spinner']" spin class="my-3" />
									<b-form-group v-else-if="mgtNavOptions.nav4.options.length > 0" :label="mgtNavOptions.nav4.label">
										<b-form-select v-model="mgtSelections.nav4" :options="mgtNavOptions.nav4.options" @change="loadNav('nav5')">
											<template v-slot:first>
												<b-form-select-option :value="null" disabled>-- Select a {{mgtNavOptions.nav4.label.toLowerCase()}} --</b-form-select-option>
											</template>
										</b-form-select>
									</b-form-group>

									<error-list :errors="page.mgtNav.nav5.errors"></error-list>
									<font-awesome-icon v-if="page.mgtNav.nav5.loading" :icon="['fas', 'spinner']" spin class="my-3" />
									<b-form-group v-else-if="mgtNavOptions.nav5.options.length > 0" :label="mgtNavOptions.nav5.label">
										<b-form-select v-model="mgtSelections.nav5" :options="mgtNavOptions.nav5.options" @change="loadNav('nav6')">
											<template v-slot:first>
												<b-form-select-option :value="null" disabled>-- Select a {{mgtNavOptions.nav5.label.toLowerCase()}} --</b-form-select-option>
											</template>
										</b-form-select>
									</b-form-group>

									<error-list :errors="page.mgtNav.nav6.errors"></error-list>
									<font-awesome-icon v-if="page.mgtNav.nav6.loading" :icon="['fas', 'spinner']" spin class="my-3" />
									<b-form-group v-else-if="mgtNavOptions.nav6.options.length > 0" :label="mgtNavOptions.nav6.label">
										<b-form-select v-model="mgtSelections.nav6" :options="mgtNavOptions.nav6.options" @change="loadNav('nav7')">
											<template v-slot:first>
												<b-form-select-option :value="null" disabled>-- Select a {{mgtNavOptions.nav6.label.toLowerCase()}} --</b-form-select-option>
											</template>
										</b-form-select>
									</b-form-group>

									<error-list :errors="page.mgtNav.nav7.errors"></error-list>
									<font-awesome-icon v-if="page.mgtNav.nav7.loading" :icon="['fas', 'spinner']" spin class="my-3" />
									<b-form-group v-else-if="mgtNavOptions.nav7.options.length > 0" :label="mgtNavOptions.nav7.label">
										<b-form-select v-model="mgtSelections.nav7" :options="mgtNavOptions.nav7.options" @change="loadNav('nav8')">
											<template v-slot:first>
												<b-form-select-option :value="null" disabled>-- Select a {{mgtNavOptions.nav7.label.toLowerCase()}} --</b-form-select-option>
											</template>
										</b-form-select>
									</b-form-group>

									<error-list :errors="page.mgtNav.nav8.errors"></error-list>
									<font-awesome-icon v-if="page.mgtNav.nav8.loading" :icon="['fas', 'spinner']" spin class="my-3" />
									<b-form-group v-else-if="mgtNavOptions.nav8.options.length > 0" :label="mgtNavOptions.nav8.label">
										<b-form-select v-model="mgtSelections.nav8" :options="mgtNavOptions.nav8.options" @change="getMgt1">
											<template v-slot:first>
												<b-form-select-option :value="null" disabled>-- Select a {{mgtNavOptions.nav8.label.toLowerCase()}} --</b-form-select-option>
											</template>
										</b-form-select>
									</b-form-group>
								</b-col>
								<b-col>
									<b-card bg-variant="light" v-if="!isNullOrEmpty(form.mgt1Id)">
										<b-card-text>
											<p>
												Management ID: <strong>{{form.mgt1Id}}</strong>
											</p>
											<p class="mb-0">
												Crop system description: <strong>{{mgtNavOptions.description}}</strong>
											</p>
										</b-card-text>
									</b-card>
								</b-col>
							</b-row>
						</b-tab>
						<b-tab title="Fertilization Rates">
							<b-alert :show="isNullOrEmpty(form.mgt1Id)" variant="warning">
								Please select your management from the previous tab.
							</b-alert>
							<div v-if="!isNullOrEmpty(form.mgt1Id)">
								<b-card bg-variant="light" v-if="form.cropAssociations.length < 1">
									<b-card-text>
										No fertilization rates applicable for management ID <strong>{{form.mgt1Id}}</strong>.
									</b-card-text>
								</b-card>
								<div v-else>
									<p class="text-center pt-2 mb-0">
										Average fertilizer rate per crop:
										<strong>{{getFertAvg('nitrogen') | number(1)}} (lb/acre) N</strong> and
										<strong>{{getFertAvg('phosphate') | number(1)}} (lb/acre) P2O5</strong>
									</p>
									<p class="text-center" style="text-decoration:underline">
										<a :href="`${publicPath}data/practices/crop-requirements.pdf`" target="_blank">Fertilizer recommendations</a>
									</p>
									<hr class="mt-3 mb-4" />
									<div v-for="(group, i) in form.cropAssociations" :key="i">
										<h4>{{group.name}}</h4>

										<table class="table mb-4 table-valign-m border-bottom">
											<thead class="thead-light">
												<tr>
													<th>Description</th>
													<th>Nitrogen</th>
													<th>Phosphate</th>
													<th class="text-center">Inc.</th>
													<th>Fertilizer Type</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(crop, j) in group.crops" :key="j">
													<td><b-form-input type="text" v-model.trim="$v.form.cropAssociations.$each[i].crops.$each[j].description.$model" :state="getValidState($v.form.cropAssociations.$each[i].crops.$each[j].description)"></b-form-input></td>
													<td><b-form-input type="number" step="any" class="number-field d-inline" v-model.number.trim="$v.form.cropAssociations.$each[i].crops.$each[j].nitrogen.$model" :state="getValidState($v.form.cropAssociations.$each[i].crops.$each[j].nitrogen)"></b-form-input> lb/acre</td>
													<td><b-form-input type="number" step="any" class="number-field d-inline" v-model.number.trim="$v.form.cropAssociations.$each[i].crops.$each[j].phosphate.$model" :state="getValidState($v.form.cropAssociations.$each[i].crops.$each[j].phosphate)"></b-form-input> lb/acre</td>
													<td class="text-center"><b-form-checkbox v-model.trim="$v.form.cropAssociations.$each[i].crops.$each[j].inc.$model"></b-form-checkbox></td>
													<td>
														<b-form-select :options="options.fertilizerTypes" v-model.trim="$v.form.cropAssociations.$each[i].crops.$each[j].fertilizerType.$model" :state="getValidState($v.form.cropAssociations.$each[i].crops.$each[j].fertilizerType)">
															<template v-slot:first>
																<b-form-select-option :value="null" disabled>-- Select a type --</b-form-select-option>
															</template>
														</b-form-select>
													</td>
												</tr>
											</tbody>
											<tfoot>
												<tr>
													<td class="text-right">Total:</td>
													<td>{{sum(group.crops, 'nitrogen')}}  lb/acre</td>
													<td>{{sum(group.crops, 'phosphate')}}  lb/acre</td>
													<td></td>
													<td></td>
												</tr>
											</tfoot>
										</table>
									</div>
								</div>
							</div>
						</b-tab>
						<b-tab title="Conservation Practices">
							<p>
								Click the buttons below to activate a conservation practice. Active practices are highlighted.
							</p>
							<b-row>
								<b-col sm>
									<b-button v-b-modal.modal-pond block class="mb-3"
											  :variant="form.pondDrainage !== 0 || form.isPondAlternative ? 'info' : 'secondary'">
										Pond (378)
									</b-button>

									<b-modal size="lg" id="modal-pond" title="Pond (378)">
										<b-card bg-variant="light" class="mb-3">
											<b-form-group label="Percent of field drained by a pond" :invalid-feedback="requiredFeedback($v.form.pondDrainage)">
												<b-form-input v-model.number.trim="$v.form.pondDrainage.$model" type="number" required step="any" :state="getValidState($v.form.pondDrainage)"></b-form-input>
											</b-form-group>
											<b-form-checkbox v-model.trim="$v.form.isPondAlternative.$model" @change="checkAltWaterSupply">
												Pond also serves as an alternative source of water for cattle
											</b-form-checkbox>
										</b-card>

										<practice-help file-name="ponds" title="Pond"
													   text="Ponds trap sediment and nutrients before they enter streams. Ponds are generally very effective sinks for nutrients. Calculate the fraction of field that drains to ponds as described in the figure."></practice-help>

										<template v-slot:modal-footer="{ ok }">
											<b-button variant="success" @click="ok()">Done</b-button>
											<b-button variant="secondary" @click="form.pondDrainage = 0; form.isPondAlternative = false;">Clear</b-button>
										</template>
									</b-modal>
								</b-col>
								<b-col sm>
									<b-button v-b-modal.modal-sediment block class="mb-3"
											  :variant="form.sedimentDrainage !== 0 ? 'info' : 'secondary'">
										Sediment Basin (350)
									</b-button>

									<b-modal size="lg" id="modal-sediment" title="Sediment Basin (350)">
										<b-card bg-variant="light" class="mb-3">
											<b-form-group label="Fraction of field draining to sedimentation basins" :invalid-feedback="requiredFeedback($v.form.sedimentDrainage)">
												<b-form-input v-model.number.trim="$v.form.sedimentDrainage.$model" type="number" required step="any" :state="getValidState($v.form.sedimentDrainage)"></b-form-input>
											</b-form-group>
										</b-card>

										<practice-help file-name="sediment" title="Sediment"
													   text="Sedimentation basins are simulated in TBET as small ponds draining less than 5 acres not designed to retain water permanently. Larger sediment basins which are designed to impound water permanently should be simulated as ponds."></practice-help>

										<template v-slot:modal-footer="{ ok }">
											<b-button variant="success" @click="ok()">Done</b-button>
											<b-button variant="secondary" @click="form.sedimentDrainage = 0;">Clear</b-button>
										</template>
									</b-modal>
								</b-col>
								<b-col sm>
									<b-button v-b-modal.modal-wetland block class="mb-3"
											  :variant="form.wetlandDrainage !== 0 ? 'info' : 'secondary'">
										Wetland (657, 658, 659)
									</b-button>

									<b-modal size="lg" id="modal-wetland" title="Wetland (657, 658, 659)">
										<b-card bg-variant="light" class="mb-3">
											<b-form-group label="Fraction of field draining to wetlands" :invalid-feedback="requiredFeedback($v.form.wetlandDrainage)">
												<b-form-input v-model.number.trim="$v.form.wetlandDrainage.$model" type="number" required step="any" :state="getValidState($v.form.wetlandDrainage)"></b-form-input>
											</b-form-group>
										</b-card>

										<practice-help file-name="wetlands" title="Wetland" multiple-pdfs :pdfs="practices.wetlandPdfs"
													   text="Wetlands are areas of permanent or periodic standing water or aquatic vegetation. Like ponds, wetlands can trap much of the sediment and nutrients entering them."></practice-help>

										<template v-slot:modal-footer="{ ok }">
											<b-button variant="success" @click="ok()">Done</b-button>
											<b-button variant="secondary" @click="form.wetlandDrainage = 0;">Clear</b-button>
										</template>
									</b-modal>
								</b-col>
							</b-row>
							<b-row>
								<b-col sm>
									<b-button v-b-modal.modal-terrace block class="mb-3"
											  :variant="form.isTerraceActive ? 'info' : 'secondary'">
										Terrace (600)
									</b-button>

									<b-modal size="lg" id="modal-terrace" title="Terrace (600)">
										<b-card bg-variant="light" class="mb-3">
											<b-form-checkbox v-model.trim="$v.form.isTerraceActive.$model">
												Terraces active
											</b-form-checkbox>
										</b-card>

										<practice-help file-name="terrace" title="Terrace"
													   text="Terraces are among the most widely used conservation practices for the reduction of sediment and nutrient losses. Terraces reduce gully formation and channel surface runoff to more protected waterways."></practice-help>

										<template v-slot:modal-footer="{ ok }">
											<b-button variant="success" @click="ok()">Done</b-button>
											<b-button variant="secondary" @click="form.isTerraceActive = false;">Clear</b-button>
										</template>
									</b-modal>
								</b-col>
								<b-col sm>
									<b-button v-b-modal.modal-contour block class="mb-3"
											  :variant="form.isContourPlantingActive ? 'info' : 'secondary'">
										Contour Farming (330)
									</b-button>

									<b-modal size="lg" id="modal-contour" title="Contour Farming (330)">
										<b-card bg-variant="light" class="mb-3">
											<b-form-checkbox v-model.trim="$v.form.isContourPlantingActive.$model">
												Contour planting active
											</b-form-checkbox>
										</b-card>

										<practice-help file-name="contour" title="Contour Farming"
													   text="Contour farming reduces erosion and nutrients loss from cultivated fields. Contour farming is automatically enabled when terraces are present."></practice-help>

										<template v-slot:modal-footer="{ ok }">
											<b-button variant="success" @click="ok()">Done</b-button>
											<b-button variant="secondary" @click="form.isContourPlantingActive = false;">Clear</b-button>
										</template>
									</b-modal>
								</b-col>
								<b-col sm>
									<b-button v-b-modal.modal-grassed-waterway block class="mb-3"
											  :variant="form.grassedWaterwayLength !== 0 || form.grassedWaterwayWidth !== 0 ? 'info' : 'secondary'">
										Grassed Waterway (412)
									</b-button>

									<b-modal size="lg" id="modal-grassed-waterway" title="Grassed Waterway (412)">
										<b-card bg-variant="light" class="mb-3">
											<b-form-group label="Total length of waterway (ft)" :invalid-feedback="requiredFeedback($v.form.grassedWaterwayLength)">
												<b-form-input v-model.number.trim="$v.form.grassedWaterwayLength.$model" type="number" required step="any" :state="getValidState($v.form.grassedWaterwayLength)"></b-form-input>
											</b-form-group>
											<b-form-group label="Average width of waterway (ft)" :invalid-feedback="requiredFeedback($v.form.grassedWaterwayWidth)">
												<b-form-input v-model.number.trim="$v.form.grassedWaterwayWidth.$model" type="number" required step="any" :state="getValidState($v.form.grassedWaterwayWidth)"></b-form-input>
											</b-form-group>
										</b-card>

										<practice-help file-name="grassed-waterway" title="Grassed Waterway"
													   text="Grassed waterways reduce sediment and nutrient losses by providing stable pathways for surface runoff to leave cultivated fields. Waterways are often combined with other practices such as terraces."></practice-help>

										<template v-slot:modal-footer="{ ok }">
											<b-button variant="success" @click="ok()">Done</b-button>
											<b-button variant="secondary" @click="form.grassedWaterwayLength = 0; form.grassedWaterwayWidth = 0;">Clear</b-button>
										</template>
									</b-modal>
								</b-col>
							</b-row>
							<b-row>
								<b-col sm>
									<b-button v-b-modal.modal-filter-strip block class="mb-3"
											  :variant="form.filterStripLength !== 0 || form.filterStripWidth !== 0 ? 'info' : 'secondary'">
										Filter Strip (393)
									</b-button>

									<b-modal size="lg" id="modal-filter-strip" title="Filter Strip (393)">
										<b-card bg-variant="light" class="mb-3">
											<b-form-group label="Total length of filter strip (ft)" :invalid-feedback="requiredFeedback($v.form.filterStripLength)">
												<b-form-input v-model.number.trim="$v.form.filterStripLength.$model" type="number" required step="any" :state="getValidState($v.form.filterStripLength)"></b-form-input>
											</b-form-group>
											<b-form-group label="Average width of filter strip (ft)" :invalid-feedback="requiredFeedback($v.form.filterStripWidth)">
												<b-form-input v-model.number.trim="$v.form.filterStripWidth.$model" type="number" step="any" :state="getValidState($v.form.filterStripWidth)"></b-form-input>
											</b-form-group>
											<div>
												<b-button type="button" variant="info" @click="page.filterStripCalc.show = true">Estimate from filter strip area</b-button>
											</div>
										</b-card>

										<practice-help file-name="filter-strip" title="Filter Strip"
													   text="Filter strips intercept and slow surface runoff before it reaches waterways. Filter strips provide an area for sediment deposition and enhance infiltration of surface runoff. Filter strip MUST be designed to provide uniform flow across the entire filter strip area to be optimally effective."></practice-help>

										<template v-slot:modal-footer="{ ok }">
											<b-button variant="success" @click="ok()">Done</b-button>
											<b-button variant="secondary" @click="form.filterStripLength = 0; form.filterStripWidth = 0;">Clear</b-button>
										</template>
									</b-modal>

									<b-modal v-model="page.filterStripCalc.show" size="md" title="Estimate Filter Strip">
										<b-form-group label="How many total acres of filter strip are in the field?">
											<b-form-input v-model.number="page.filterStripCalc.area" type="number" step="any"></b-form-input>
										</b-form-group>

										<div slot="modal-footer">
											<b-button type="button" @click="calculateFilterStrip" variant="success">Calculate</b-button>
											<b-button type="button" variant="secondary" @click="page.filterStripCalc.show = false" class="ml-1">Cancel</b-button>
										</div>
									</b-modal>
								</b-col>
								<b-col sm>
									<b-button v-b-modal.modal-riparian-forest-buffer block class="mb-3"
											  :variant="form.riparianBufferLength !== 0 || form.riparianBufferWidth !== 0 ? 'info' : 'secondary'">
										Riparian Forest Buffer (391)
									</b-button>

									<b-modal size="lg" id="modal-riparian-forest-buffer" title="Riparian Forest Buffer (391)">
										<b-card bg-variant="light" class="mb-3">
											<b-form-group label="Total length of riparian buffer (ft)" :invalid-feedback="requiredFeedback($v.form.riparianBufferLength)">
												<b-form-input v-model.number.trim="$v.form.riparianBufferLength.$model" type="number" required step="any" :state="getValidState($v.form.riparianBufferLength)"></b-form-input>
											</b-form-group>
											<b-form-group label="Average width of forest buffer from field to stream (ft)" :invalid-feedback="requiredFeedback($v.form.riparianBufferWidth)">
												<b-form-input v-model.number.trim="$v.form.riparianBufferWidth.$model" type="number" required step="any" :state="getValidState($v.form.riparianBufferWidth)"></b-form-input>
											</b-form-group>
											<div>
												<b-button type="button" variant="info" @click="page.riparianCalc.show = true">Estimate from riparian area</b-button>
											</div>
										</b-card>

										<practice-help file-name="riparian-forest-buffer" title="Riparian Forest Buffer"
													   text="Riparian forest buffers filter surface runoff before it reaches stream. They also enhance stream bank stability and reduce stream migration. Riparian forest buffers reduce water temperatures and provide habitat for wildlife."></practice-help>

										<template v-slot:modal-footer="{ ok }">
											<b-button variant="success" @click="ok()">Done</b-button>
											<b-button variant="secondary" @click="form.riparianBufferLength = 0; form.riparianBufferWidth = 0;">Clear</b-button>
										</template>
									</b-modal>

									<b-modal v-model="page.riparianCalc.show" size="md" title="Estimate Riparian Forest Buffer">
										<b-form-group label="How many total acres of riparian zone are in the field?">
											<b-form-input v-model.number="page.riparianCalc.area" type="number" step="any"></b-form-input>
										</b-form-group>

										<div slot="modal-footer">
											<b-button type="button" @click="calculateRiparian" variant="success">Calculate</b-button>
											<b-button type="button" variant="secondary" @click="page.riparianCalc.show = false" class="ml-1">Cancel</b-button>
										</div>
									</b-modal>
								</b-col>
								<b-col sm>
									<b-button v-b-modal.modal-subsurface-drain block class="mb-3"
											  :variant="form.depthToDrains !== 0 && form.isSubsurfaceDrainEnabled ? 'info' : 'secondary'">
										Subsurface Drain (606)
									</b-button>

									<b-modal size="lg" id="modal-subsurface-drain" title="Subsurface Drain (606)">
										<b-card bg-variant="light" class="mb-3">
											<b-form-group label="Approximate depth to drains (ft)" :invalid-feedback="requiredFeedback($v.form.depthToDrains)">
												<b-form-input v-model.number.trim="$v.form.depthToDrains.$model" type="number" required step="any" :state="getValidState($v.form.depthToDrains)"></b-form-input>
											</b-form-group>
											<b-form-checkbox v-model.trim="$v.form.isSubsurfaceDrainEnabled.$model">
												Enable subsurface drain
											</b-form-checkbox>
										</b-card>

										<practice-help file-name="subsurface-drain" title="Subsurface Drain"
													   text="Subsurface drains may improve crop production and reduce surface sediment and nutrient losses. Subsurface drains may increase losses of soluble nutrients in drainage water."></practice-help>

										<template v-slot:modal-footer="{ ok }">
											<b-button variant="success" @click="ok()">Done</b-button>
											<b-button variant="secondary" @click="form.depthToDrains = 0; form.isSubsurfaceDrainEnabled = false;">Clear</b-button>
										</template>
									</b-modal>
								</b-col>
							</b-row>
							<b-row>
								<b-col sm>
									<b-button v-b-modal.modal-manure-application-setbacks block class="mb-3"
											  :variant="form.manureApplicationSetbacksArea !== 0 ? 'info' : 'secondary'">
										Manure Setback
									</b-button>

									<b-modal size="lg" id="modal-manure-application-setbacks" title="Manure Application Setbacks">
										<b-card bg-variant="light" class="mb-3">
											<b-form-group label="Total area in manure application setbacks (acres)" :invalid-feedback="requiredFeedback($v.form.manureApplicationSetbacksArea)">
												<b-form-input v-model.number.trim="$v.form.manureApplicationSetbacksArea.$model" type="number" required step="any" :state="getValidState($v.form.manureApplicationSetbacksArea)"></b-form-input>
											</b-form-group>
										</b-card>

										<practice-help file-name="manure-application-setbacks" title="Manure Application Setbacks" no-pdf
													   text="Manure application Setbacks prevent manure application in especially sensitive areas near stream, wellheads, property boundaries, etc. The area in Setbacks may be a significant fraction of the total acreage in some fields. Setbacks are not buffers. Setback areas receive no special management and are not segregated from the main field."></practice-help>

										<template v-slot:modal-footer="{ ok }">
											<b-button variant="success" @click="ok()">Done</b-button>
											<b-button variant="secondary" @click="form.manureApplicationSetbacksArea = 0;">Clear</b-button>
										</template>
									</b-modal>
								</b-col>
								<b-col sm>
									<b-button v-b-modal.modal-grade-stabilization-structure block class="mb-3"
											  :variant="form.totalStructureDrop !== 0 ? 'info' : 'secondary'">
										Grade Stabilization (410)
									</b-button>

									<b-modal size="lg" id="modal-grade-stabilization-structure" title="Grade Stabilization Structure (410)">
										<b-card bg-variant="light" class="mb-3">
											<b-form-group label="Total structure drop (ft)" :invalid-feedback="requiredFeedback($v.form.totalStructureDrop)">
												<b-form-input v-model.number.trim="$v.form.totalStructureDrop.$model" type="number" required step="any" :state="getValidState($v.form.totalStructureDrop)"></b-form-input>
											</b-form-group>
										</b-card>

										<practice-help file-name="grade-stabilization-structure" title="Grade Stabilization Structure"
													   text="Grade stabilization structures reduce the slope of field waterways or are located to halt advancing waterway headcuts."></practice-help>

										<template v-slot:modal-footer="{ ok }">
											<b-button variant="success" @click="ok()">Done</b-button>
											<b-button variant="secondary" @click="form.totalStructureDrop = 0;">Clear</b-button>
										</template>
									</b-modal>
								</b-col>
								<b-col sm>
									<b-button v-b-modal.modal-riparian-access-control block class="mb-3"
											  :variant="form.areLivestockExcludedFromRiparianArea ? 'info' : 'secondary'">
										Riparian Exclusion (472)
									</b-button>

									<b-modal size="lg" id="modal-riparian-access-control" title="Riparian Exclusion (472)">
										<b-card bg-variant="light" class="mb-3">
											<b-form-checkbox v-model.trim="$v.form.areLivestockExcludedFromRiparianArea.$model">
												Livestock excluded from riparian area
											</b-form-checkbox>
										</b-card>

										<practice-help file-name="riparian-access-control" title="Riparian Access Control"
													   text="The exclusion of livestock from riparian areas is an effective method to improve streambank stability and reduce the direct application of animal manures to surface waters."></practice-help>

										<template v-slot:modal-footer="{ ok }">
											<b-button variant="success" @click="ok()">Done</b-button>
											<b-button variant="secondary" @click="form.areLivestockExcludedFromRiparianArea = false;">Clear</b-button>
										</template>
									</b-modal>
								</b-col>
							</b-row>
							<b-row>
								<b-col sm>
									<b-button v-b-modal.modal-watering-facility block class="mb-3"
											  :variant="form.isAlternativeWaterSupplyEnabled ? 'info' : 'secondary'">
										Watering Facility (614)
									</b-button>

									<b-modal size="lg" id="modal-watering-facility" title="Watering Facility (614)">
										<b-card bg-variant="light" class="mb-3">
											<b-form-checkbox v-model.trim="$v.form.isAlternativeWaterSupplyEnabled.$model">
												Enable alternative water supply
											</b-form-checkbox>
										</b-card>

										<practice-help file-name="watering-facility" title="Watering Facility"
													   text="Providing alternative water supplies for cattle which would otherwise water from streams has been demonstrated to significantly reduce cattle usage of riparian areas. Reducing cattle usage of riparian areas reduces the quantity of manure deposited directly into streams."></practice-help>

										<template v-slot:modal-footer="{ ok }">
											<b-button variant="success" @click="ok()">Done</b-button>
											<b-button variant="secondary" @click="form.isAlternativeWaterSupplyEnabled = false;">Clear</b-button>
										</template>
									</b-modal>
								</b-col>
								<b-col sm>
									<b-button v-b-modal.modal-heavy-use block class="mb-3"
											  :variant="form.heavyUseArea !== 0 ? 'info' : 'secondary'">
										Heavy Use Area (561)
									</b-button>

									<b-modal size="lg" id="modal-heavy-use" title="Heavy Use Area (561)">
										<b-card bg-variant="light" class="mb-3">
											<b-form-select v-model.trim="$v.form.heavyUseArea.$model" :options="options.heavyUseAreas" :state="getValidState($v.form.heavyUseArea)"></b-form-select>
										</b-card>

										<practice-help file-name="heavy-use" title="Heavy Use Area Protection"
													   text="The establishment of protective covering of vegetation, concrete, or gravel on heavily utilized areas of bare soil can reduce the losses of sediment and nutrients."></practice-help>

										<template v-slot:modal-footer="{ ok }">
											<b-button variant="success" @click="ok()">Done</b-button>
											<b-button variant="secondary" @click="form.heavyUseArea = 0;">Clear</b-button>
										</template>
									</b-modal>
								</b-col>
								<b-col sm>
									<b-button v-b-modal.modal-prescribed-grazing block class="mb-3"
											  :variant="form.isPrescribedGrazingEnabled ? 'info' : 'secondary'">
										Prescribed Grazing (528)
									</b-button>

									<b-modal size="lg" id="modal-prescribed-grazing" title="Prescribed Grazing (528)">
										<b-card bg-variant="light" class="mb-3">
											<b-form-checkbox v-model.trim="$v.form.isPrescribedGrazingEnabled.$model">
												Enable prescribed grazing
											</b-form-checkbox>
										</b-card>

										<practice-help file-name="prescribed-grazing" title="Prescribed Grazing"
													   text="Prescribed grazing maintains pasture productivity and helps prevent overgrazing. Overgrazed pastures have higher sediment and nutrient losses than optimally managed ones."></practice-help>

										<template v-slot:modal-footer="{ ok }">
											<b-button variant="success" @click="ok()">Done</b-button>
											<b-button variant="secondary" @click="form.isPrescribedGrazingEnabled = false;">Clear</b-button>
										</template>
									</b-modal>
								</b-col>
							</b-row>
							<b-row>
								<b-col sm>
									<b-button v-b-modal.modal-residue block class="mb-3"
											  :variant="form.minCropResidue !== 0 ? 'info' : 'secondary'">
										Residue Management (344)
									</b-button>

									<b-modal size="lg" id="modal-residue" title="Residue Management (344)">
										<b-card bg-variant="light" class="mb-3">
											<b-form-group label="Minimum allowable crop residue (%)" :invalid-feedback="requiredFeedback($v.form.minCropResidue)">
												<b-form-input v-model.number.trim="$v.form.minCropResidue.$model" type="number" required step="any" :state="getValidState($v.form.minCropResidue)"></b-form-input>
											</b-form-group>
										</b-card>

										<practice-help file-name="residue" title="Residue Management"
													   text="Crop residues dissipate the erosive energy in falling raindrops and flowing surface runoff. Maintaining at least 30% soil cover significantly reduces sediment and nutrient losses."></practice-help>

										<template v-slot:modal-footer="{ ok }">
											<b-button variant="success" @click="ok()">Done</b-button>
											<b-button variant="secondary" @click="form.minCropResidue = 0;">Clear</b-button>
										</template>
									</b-modal>
								</b-col>
								<b-col sm>
									<b-button v-b-modal.modal-forage block class="mb-3"
											  :variant="form.minBiomass !== 0 ? 'info' : 'secondary'">
										Forage Harvest Management (511)
									</b-button>

									<b-modal size="lg" id="modal-forage" title="Forage Harvest Management (511)">
										<b-card bg-variant="light" class="mb-3">
											<b-form-group label="Minimum residual biomass after hay cutting (lb/acre)" :invalid-feedback="requiredFeedback($v.form.minBiomass)">
												<b-form-input v-model.number.trim="$v.form.minBiomass.$model" type="number" required step="any" :state="getValidState($v.form.minBiomass)"></b-form-input>
											</b-form-group>
										</b-card>

										<practice-help file-name="forage" title="Forage Harvest Management"
													   text="To simulate the effects of forage harvest management, you may specify the amount of cut forage that remains after a hay cutting. Cut forage plants at a height that will promote the vigor and health of the desired species."></practice-help>

										<template v-slot:modal-footer="{ ok }">
											<b-button variant="success" @click="ok()">Done</b-button>
											<b-button variant="secondary" @click="form.minBiomass = 0;">Clear</b-button>
										</template>
									</b-modal>
								</b-col>
								<b-col sm>
									<b-button v-b-modal.modal-mechanical-treatment block class="mb-3"
											  :variant="form.hasRegularMechanicalTreatment ? 'info' : 'secondary'">
										Grazing Mechanical Treatment (548)
									</b-button>

									<b-modal size="lg" id="modal-mechanical-treatment" title="Grazing Land Mechanical Treatment (548)">
										<b-card bg-variant="light" class="mb-3">
											<b-form-checkbox v-model.trim="$v.form.hasRegularMechanicalTreatment.$model">
												Regular mechanical treatment
											</b-form-checkbox>
										</b-card>

										<practice-help file-name="mechanical-treatment" title="Grazing Land Mechanical Treatment"
													   text="Regular mechanical treatment of grazing field may reduce compaction and improve infiltration."></practice-help>

										<template v-slot:modal-footer="{ ok }">
											<b-button variant="success" @click="ok()">Done</b-button>
											<b-button variant="secondary" @click="form.hasRegularMechanicalTreatment = false;">Clear</b-button>
										</template>
									</b-modal>
								</b-col>
							</b-row>
							<b-row>
								<b-col sm>
									<b-button v-b-modal.modal-poultry-alum block class="mb-3"
											  :variant="form.poultryAlum !== 0 ? 'info' : 'secondary'">
										Alum Treated Manure (591)
									</b-button>

									<b-modal size="lg" id="modal-poultry-alum" title="Poultry Alum Treated Manure (591)">
										<b-card bg-variant="light" class="mb-3">
											<b-form-select v-model.trim="$v.form.poultryAlum.$model" :options="options.poultryAlums" :state="getValidState($v.form.poultryAlum)"></b-form-select>
										</b-card>

										<practice-help file-name="poultry-alum" title="Waste Amendment (Poultry-Alum)"
													   text="The addition of aluminum (i.e., alum), iron or calcium containing amendments to animal manures have been shown to reduce the solubility of phosphorus in the wastes. When amended wastes are applied to agricultural fields, soluble runoff P concentrations are reduced compared to unamended manures. The rate of alum application is very important. Lower rates of alum application used for ammonia control may not significantly affect phosphorus loss after application. The recommended rate is 0.2 lbs per bird for broilers (equivalent to 2 tons per 20,000 bird house applied each flock). Field receiving litter amended with significantly less than the recommended rate receive less benefit from alum as a BMP."></practice-help>

										<template v-slot:modal-footer="{ ok }">
											<b-button variant="success" @click="ok()">Done</b-button>
											<b-button variant="secondary" @click="form.poultryAlum = 0;">Clear</b-button>
										</template>
									</b-modal>
								</b-col>
								<b-col sm>
									<b-button v-b-modal.modal-land-leveling block class="mb-3"
											  :variant="form.isLandLevelingActive ? 'info' : 'secondary'">
										Irrigation Land Leveling (464)
									</b-button>

									<b-modal size="lg" id="modal-land-leveling" title="Irrigation Land Leveling (464)">
										<b-card bg-variant="light" class="mb-3">
											<b-form-checkbox v-model.trim="$v.form.isLandLevelingActive.$model">
												Land leveling active
											</b-form-checkbox>
										</b-card>

										<practice-help file-name="land-leveling" title="Irrigation Land Leveling"
													   text="Land leveling reduces soil erosion and improves irrigation efficiency."></practice-help>

										<template v-slot:modal-footer="{ ok }">
											<b-button variant="success" @click="ok()">Done</b-button>
											<b-button variant="secondary" @click="form.isLandLevelingActive = false;">Clear</b-button>
										</template>
									</b-modal>
								</b-col>
								<b-col sm>
									<b-button v-b-modal.modal-waste-lagoon block class="mb-3"
											  :variant="form.wasteCaptured !== 0 && form.isWasteTreatmentLagoonActive ? 'info' : 'secondary'">
										Waste Lagoon (359)
									</b-button>

									<b-modal size="lg" id="modal-waste-lagoon" title="Waste Lagoon (359)">
										<b-card bg-variant="light" class="mb-3">
											<b-form-group label="Percent waste captured" :invalid-feedback="requiredFeedback($v.form.wasteCaptured)">
												<b-form-input v-model.number.trim="$v.form.wasteCaptured.$model" type="number" required step="any" :state="getValidState($v.form.wasteCaptured)"></b-form-input>
											</b-form-group>
											<b-form-checkbox v-model.trim="$v.form.isWasteTreatmentLagoonActive.$model">
												Waste treatment lagoon active
											</b-form-checkbox>
										</b-card>

										<practice-help file-name="waste-lagoon" title="Waste Lagoon"
													   text="Waste storage lagoons are non-discharging structures in TBET. This makes them very effective practices where they are constructed. This practice assumes that lagoon effluent is used for irrigation elsewhere. You should include an irrigation area in your farm for this purpose. This application area may exhibit increase nutrient losses."></practice-help>

										<template v-slot:modal-footer="{ ok }">
											<b-button variant="success" @click="ok()">Done</b-button>
											<b-button variant="secondary" @click="form.wasteCaptured = 0; form.isWasteTreatmentLagoonActive = false;">Clear</b-button>
										</template>
									</b-modal>
								</b-col>
							</b-row>
							<b-row>
								<b-col sm="4">
									<b-button v-b-modal.modal-manure-incorporation block class="mb-3"
											  :variant="form.isManureIncorporationActive ? 'info' : 'secondary'">
										Manure Incorporation
									</b-button>

									<b-modal size="lg" id="modal-manure-incorporation" title="Manure Incorporation">
										<b-card bg-variant="light" class="mb-3">
											<b-form-checkbox v-model.trim="$v.form.isManureIncorporationActive.$model">
												Manure incorporation active
											</b-form-checkbox>
										</b-card>

										<practice-help file-name="manure-incorporation" title="Manure Incorporation" no-pdf
													   text="The incorporation of animal manures during application reduced the losses of nutrients and bacteria in surface runoff. The incorporation of the materials may also reduce soil test phosphorus results depending upon the depth of incorporation."></practice-help>

										<template v-slot:modal-footer="{ ok }">
											<b-button variant="success" @click="ok()">Done</b-button>
											<b-button variant="secondary" @click="form.isManureIncorporationActive = false;">Clear</b-button>
										</template>
									</b-modal>
								</b-col>
							</b-row>
						</b-tab>
						<b-tab title="Non-simulated Practices">
							<b-card bg-variant="light" class="mb-4">
								<b-card-text>
									TBET and SWAT are not capable of directly simulating every conservation practice. To allow these practices to be evaluated, a fixed set of conservation practice efficiencies are available.
									These efficiencies are derived from the Integrated Storm Water Management (iSWM) program and the Spreadsheet Tool for Estimating Pollutant Load (STEPL) databases. Users may also provide efficiencies by constituent.
								</b-card-text>
							</b-card>

							<table class="table mb-4 table-valign-m border-bottom" v-if="form.nonSimulatedPractices.length > 0">
								<thead>
									<tr>
										<th></th>
										<th colspan="4" class="text-center">Removal Efficiency (%)</th>
										<th></th>
									</tr>
								</thead>
								<thead class="thead-light">
									<tr>
										<th class="border-right">Description</th>
										<th>Sediment</th>
										<th>Nitrogen</th>
										<th>Phosphate</th>
										<th>Bacteria</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(practice, i) in form.nonSimulatedPractices" :key="i">
										<td class="border-right">
											<b-form-input type="text" list="non-sim-practices-list" debounce="500" @change="setNonSimulatedPractice(practice)"
														  v-model.trim="$v.form.nonSimulatedPractices.$each[i].description.$model" :state="getValidState($v.form.nonSimulatedPractices.$each[i].description)"></b-form-input>
										</td>
										<td><b-form-input type="number" step="any" class="number-field d-inline" v-model.number.trim="$v.form.nonSimulatedPractices.$each[i].sediment.$model" :state="getValidState($v.form.nonSimulatedPractices.$each[i].sediment)"></b-form-input></td>
										<td><b-form-input type="number" step="any" class="number-field d-inline" v-model.number.trim="$v.form.nonSimulatedPractices.$each[i].nitrogen.$model" :state="getValidState($v.form.nonSimulatedPractices.$each[i].nitrogen)"></b-form-input></td>
										<td><b-form-input type="number" step="any" class="number-field d-inline" v-model.number.trim="$v.form.nonSimulatedPractices.$each[i].phosphate.$model" :state="getValidState($v.form.nonSimulatedPractices.$each[i].phosphate)"></b-form-input></td>
										<td><b-form-input type="number" step="any" class="number-field d-inline" v-model.number.trim="$v.form.nonSimulatedPractices.$each[i].bacteria.$model" :state="getValidState($v.form.nonSimulatedPractices.$each[i].bacteria)"></b-form-input></td>
										<td>
											<button type="button" class="plain text-danger" v-b-tooltip.hover.right="'Remove'" @click="removeNonSimulatedPractice(practice)">
												<font-awesome-icon :icon="['fas', 'times']" />
											</button>
										</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td class="text-right border-right">Total non-simulated conservation practice effort:</td>
										<td>{{sumEffort(form.nonSimulatedPractices, 'sediment') | number(0)}}</td>
										<td>{{sumEffort(form.nonSimulatedPractices, 'nitrogen') | number(0)}}</td>
										<td>{{sumEffort(form.nonSimulatedPractices, 'phosphate') | number(0)}}</td>
										<td>{{sumEffort(form.nonSimulatedPractices, 'bacteria') | number(0)}}</td>
										<td></td>
									</tr>
								</tfoot>
							</table>

							<datalist id="non-sim-practices-list">
								<option v-for="(practice, i) in options.nonSimulatedPractices" :key="i">{{ practice.description }}</option>
							</datalist>

							<b-button @click="addNonSimulatedPractice" variant="info">Add a non-simulated practice</b-button>
						</b-tab>
					</b-tabs>
				</div>

				<fixed-action-bar :cols-lg="12" :offset-lg="0" :cols="12" :offset="0" fixed-width>
					<b-button v-if="isUpdate" variant="light" @click="nextTab(-1)" class="border mr-2" :disabled="tabs.index == 0" title="Previous tab"><font-awesome-icon icon="chevron-left" /></b-button>
					<b-button v-if="isUpdate" variant="light" @click="nextTab(1)" class="border mr-2" :disabled="tabs.index == tabs.count - 1" title="Next tab"><font-awesome-icon icon="chevron-right" /></b-button>

					<b-button type="button" variant="success" @click="saveOrContinue" class="mr-2" :disabled="(tabs.index == tabs.count - 1 || isUpdate) && !$v.$anyDirty">
						<font-awesome-icon v-if="page.saving" :icon="['fas', 'spinner']" spin />
						{{tabs.index == tabs.count - 1 || isUpdate ? 'Save' : 'Continue'}}
					</b-button>
					<parent-button back-to="field"></parent-button>

					<b-button v-if="isUpdate" variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
				</fixed-action-bar>
			</b-form>
		</div>

		<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
			<error-list :errors="page.delete.errors"></error-list>

			<p>
				Are you sure you want to delete this plan implementation?
				This action is permanent and cannot be undone.
			</p>

			<div slot="modal-footer">
				<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
				<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</auth-container>
</template>

<script>
	import { required, decimal } from 'vuelidate/lib/validators';
	import PracticeHelp from '@/components/PracticeHelp';

	export default {
		name: 'ImplementationForm',
		components: {
			PracticeHelp
		},
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			planId: {
				type: String,
				default: null
			},
			fieldId: {
				type: String,
				default: null
			},
			implementationId: {
				type: String,
				default: null
			},
			county: {
				type: String,
				default: null
			},
			planNumber: {
				type: String,
				default: null
			},
			fieldName: {
				type: String,
				default: null
			}
		},
		data() {
			return {
				page: {
					id: this.fieldId,
					errors: [],
					loading: false,
					showLogin: false,
					saving: false,
					saveErrors: [],
					delete: {
						show: false,
						errors: [],
						saving: false
					},
					copy: {
						show: false,
						errors: [],
						saving: false
					},
					data: {
						loading: false,
						errors: []
					},
					mgtNav: {
						cropSystem: { loading: false, errors: [] },
						cropName: { loading: false, errors: [] },
						region: { loading: false, errors: [] },
						nav4: { loading: false, errors: [] },
						nav5: { loading: false, errors: [] },
						nav6: { loading: false, errors: [] },
						nav7: { loading: false, errors: [] },
						nav8: { loading: false, errors: [] }
					},
					filterStripCalc: {
						show: false,
						area: 0
					},
					riparianCalc: {
						show: false,
						area: 0
					}
				},
				tabs: {
					index: 0,
					count: 5
				},
				form: {
					fieldId: Number(this.fieldId),
					implementationStatus: 0,
					bareSoil: 0,
					soilTestP: 0,

					//Management
					mgt1Id: null,
					cropAssociations: [],

					//Conservation Practices Data
					pondDrainage: 0,
					isPondAlternative: false,
					depthToDrains: 0,
					isSubsurfaceDrainEnabled: false,
					wasteCaptured: 0,
					isWasteTreatmentLagoonActive: false,
					grassedWaterwayLength: 0,
					grassedWaterwayWidth: 0,
					filterStripLength: 0,
					filterStripWidth: 0,
					riparianBufferLength: 0,
					riparianBufferWidth: 0,
					sedimentDrainage: 0,
					wetlandDrainage: 0,
					manureApplicationSetbacksArea: 0,
					totalStructureDrop: 0,
					minCropResidue: 0,
					minBiomass: 0,
					isTerraceActive: false,
					isContourPlantingActive: false,
					isManureIncorporationActive: false,
					areLivestockExcludedFromRiparianArea: false,
					isAlternativeWaterSupplyEnabled: false,
					isPrescribedGrazingEnabled: false,
					heavyUseArea: 0,
					poultryAlum: 0,
					hasRegularMechanicalTreatment: false,
					isLandLevelingActive: false,
					nonSimulatedPractices: []
				},
				options: {
					statuses: [],
					cropSystems: [],
					fertilizerTypes: [],
					nonSimulatedPractices: [],
					heavyUseAreas: [],
					poultryAlums: []
				},
				mgtNavOptions: {
					cropName: [],
					region: [],
					nav4: {
						label: null,
						options: []
					},
					nav5: {
						label: null,
						options: []
					},
					nav6: {
						label: null,
						options: []
					},
					nav7: {
						label: null,
						options: []
					},
					nav8: {
						label: null,
						options: []
					},
					description: null
				},
				mgtSelections: {
					cropSystem: null,
					cropName: null,
					region: null,
					nav4: null,
					nav5: null,
					nav6: null,
					nav7: null,
					nav8: null
				},
				practices: {
					wetlandPdfs: [
						{ fileName: 'wetlands-657', title: 'NRCS Standard 657' },
						{ fileName: 'wetlands-658', title: 'NRCS Standard 658' },
						{ fileName: 'wetlands-659', title: 'NRCS Standard 659' }
					]
				}
			}
		},
		validations: {
			form: {
				implementationStatus: { required },
				bareSoil: { required, decimal },
				soilTestP: { required, decimal },
				mgt1Id: { required },
				cropAssociations: {
					$each: {
						name: {},
						crops: {
							$each: {
								description: { required },
								nitrogen: { required, decimal },
								phosphate: { required, decimal },
								inc: {},
								fertilizerType: {},
								year: {},
								month: {},
								day: {}
							}
						}
					}
				},
				pondDrainage: { required, decimal },
				isPondAlternative: {},
				depthToDrains: { required, decimal },
				isSubsurfaceDrainEnabled: {},
				wasteCaptured: { required, decimal },
				isWasteTreatmentLagoonActive: {},
				grassedWaterwayLength: { required, decimal },
				grassedWaterwayWidth: { required, decimal },
				filterStripLength: { required, decimal },
				filterStripWidth: { required, decimal },
				riparianBufferLength: { required, decimal },
				riparianBufferWidth: { required, decimal },
				sedimentDrainage: { required, decimal },
				wetlandDrainage: { required, decimal },
				manureApplicationSetbacksArea: { required, decimal },
				totalStructureDrop: { required, decimal },
				minCropResidue: { required, decimal },
				minBiomass: { required, decimal },
				isTerraceActive: {},
				isContourPlantingActive: {},
				isManureIncorporationActive: {},
				areLivestockExcludedFromRiparianArea: {},
				isAlternativeWaterSupplyEnabled: {},
				isPrescribedGrazingEnabled: {},
				heavyUseArea: {},
				poultryAlum: {},
				hasRegularMechanicalTreatment: {},
				isLandLevelingActive: {},
				nonSimulatedPractices: {
					$each: {
						description: { required },
						sediment: { required, decimal },
						nitrogen: { required, decimal },
						phosphate: { required, decimal },
						bacteria: { required, decimal }
					}
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				let url = this.isUpdate ? `implementations/${this.implementationId}` : `implementations/options`;

				try {
					const response = await this.$http.get(url, this.getTokenHeader());
					this.log(response.data);

					if (this.isUpdate) {
						this.form = response.data.implementation;
						this.options = response.data.options;
						this.mgtSelections = response.data.mgtSelections;
						this.mgtNavOptions = response.data.mgtNavOptions;

						if (this.isNullOrEmpty(this.form.nonSimulatedPractices)) this.form.nonSimulatedPractices = [];
					} else {
						this.options = response.data;
					}
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async saveOrContinue() {
				if (this.tabs.index == this.tabs.count - 1 || this.isUpdate) {
					await this.save();
				} else {
					this.nextTab(1);
				}
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;
				this.$v.form.$touch();

				if (this.$v.$invalid) {
					this.log(this.$v);
					this.page.saveErrors.push('Please check the tabs below for errors and try again. You must select management to continue.');
				} else {
					this.log(this.form);
					try {
						if (!this.isUpdate) {
							const response = await this.$http.post('implementations', this.form, this.getTokenHeader());
							this.log(response.data);
							//this.$router.push({ name: 'ImplementationEdit', params: { id: this.planId, fieldId: this.fieldId, implementationId: response.data.toString() } }).catch(err => { this.log(err); });
							this.$router.push({ name: 'FieldView', params: { id: this.planId, fieldId: this.fieldId } }).catch(err => { this.log(err); });
						} else {
							await this.$http.put(`implementations/${this.implementationId}`, this.form, this.getTokenHeader());
						}

						this.$bvToast.show('changes-saved');
						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			async copy() {
				this.page.copy.errors = [];
				this.page.copy.saving = true;

				try {
					const response = await this.$http.post(`implementations/copy/${this.implementationId}`, {}, this.getTokenHeader());
					this.page.copy.show = false;
					this.$router.push({ name: 'ImplementationEdit', params: { id: this.planId, fieldId: this.fieldId, implementationId: response.data.toString() } }).catch(err => { this.log(err); });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.copy.errors = this.logError(error);
				}

				this.page.copy.saving = false;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`implementations/${this.implementationId}`, this.getTokenHeader());
					this.log(response.data);
					this.$router.push({ name: 'FieldView', params: { id: this.planId, fieldId: this.fieldId } }).catch(err => { this.log(err); });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			async loadNav(type) {
				this.page.mgtNav[type].errors = [];
				this.page.mgtNav[type].loading = true;
				this.form.mgt1Id = null;

				switch (type) {
					case 'cropName':
						this.mgtNavOptions.region = [];
						this.mgtNavOptions.nav4.options = [];
						this.mgtNavOptions.nav5.options = [];
						this.mgtNavOptions.nav6.options = [];
						this.mgtNavOptions.nav7.options = [];
						this.mgtNavOptions.nav8.options = [];
						this.mgtSelections.cropName = null;
						this.mgtSelections.region = null;
						this.mgtSelections.nav4 = null;
						this.mgtSelections.nav5 = null;
						this.mgtSelections.nav6 = null;
						this.mgtSelections.nav7 = null;
						this.mgtSelections.nav8 = null;
						break;
					case 'region':
						this.mgtNavOptions.nav4.options = [];
						this.mgtNavOptions.nav5.options = [];
						this.mgtNavOptions.nav6.options = [];
						this.mgtNavOptions.nav7.options = [];
						this.mgtNavOptions.nav8.options = [];
						this.mgtSelections.region = null;
						this.mgtSelections.nav4 = null;
						this.mgtSelections.nav5 = null;
						this.mgtSelections.nav6 = null;
						this.mgtSelections.nav7 = null;
						this.mgtSelections.nav8 = null;
						break;
					case 'nav4':
						this.mgtNavOptions.nav5.options = [];
						this.mgtNavOptions.nav6.options = [];
						this.mgtNavOptions.nav7.options = [];
						this.mgtNavOptions.nav8.options = [];
						this.mgtSelections.nav4 = null;
						this.mgtSelections.nav5 = null;
						this.mgtSelections.nav6 = null;
						this.mgtSelections.nav7 = null;
						this.mgtSelections.nav8 = null;
						break;
					case 'nav5':
						this.mgtNavOptions.nav6.options = [];
						this.mgtNavOptions.nav7.options = [];
						this.mgtNavOptions.nav8.options = [];
						this.mgtSelections.nav5 = null;
						this.mgtSelections.nav6 = null;
						this.mgtSelections.nav7 = null;
						this.mgtSelections.nav8 = null;
						break;
					case 'nav6':
						this.mgtNavOptions.nav7.options = [];
						this.mgtNavOptions.nav8.options = [];
						this.mgtSelections.nav6 = null;
						this.mgtSelections.nav7 = null;
						this.mgtSelections.nav8 = null;
						break;
					case 'nav7':
						this.mgtNavOptions.nav8.options = [];
						this.mgtSelections.nav7 = null;
						this.mgtSelections.nav8 = null;
						break;
				}

				try {
					const response = await this.$http.post(`implementations/${type}`, this.mgtSelections, this.getTokenHeader());
					this.log(response.data);
					this.mgtNavOptions[type] = response.data;
					if (response.data.options.length < 1) {
						await this.getMgt1();
					}
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.mgtNav[type].errors = this.logError(error);
				}

				this.page.mgtNav[type].loading = false;
			},
			async getMgt1() {
				this.page.data.errors = [];
				this.page.data.loading = true;

				try {
					const response = await this.$http.post(`implementations/mgt1`, this.mgtSelections, this.getTokenHeader());
					this.log(response.data);
					if (this.isNullOrEmpty(response.data)) {
						this.page.data.errors.push('Could not find matching management.');
					} else {
						this.form.mgt1Id = response.data.mgt1Id;
						this.form.cropAssociations = response.data.cropAssociations;
						this.mgtNavOptions.description = response.data.description;
						this.$v.form.mgt1Id.$touch();
						this.updatedMgt = true;
					}
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.data.errors = this.logError(error);
				}

				this.page.data.loading = false;
			},
			getFertAvg(prop) {
				let count = this.form.cropAssociations.length;
				if (count < 1) return 0;

				let total = 0;
				for (let group of this.form.cropAssociations) {
					total += this.sum(group.crops, prop);
				}
				return total / count;
			},
			addNonSimulatedPractice() {
				this.form.nonSimulatedPractices.push({
					description: null,
					sediment: 0,
					nitrogen: 0,
					phosphate: 0,
					bacteria: 0
				});
			},
			removeNonSimulatedPractice(item) {
				this.form.nonSimulatedPractices = this.form.nonSimulatedPractices.filter(function (el) { return el != item; });
				this.$v.form.nonSimulatedPractices.$touch();
			},
			setNonSimulatedPractice(item) {
				let match = this.options.nonSimulatedPractices.filter(function (el) { return el.description === item.description; });
				if (match.length > 0) {
					this.log(match);
					item.sediment = match[0].sediment;
					item.nitrogen = match[0].nitrogen;
					item.phosphate = match[0].phosphate;
					item.bacteria = match[0].bacteria;
				}
			},
			scrollLeft(amount = 100) {
				let content = document.querySelector(".nav-tabs");
				if (content !== null) content.scrollLeft -= amount;
			},
			scrollRight(amount = 100) {
				let content = document.querySelector(".nav-tabs");
				if (content !== null) content.scrollLeft += amount;
			},
			nextTab(position) {
				var maxTabPos = this.tabs.count - 1;
				this.tabs.index += position;

				if (this.tabs.index > maxTabPos)
					this.tabs.index = 0;
				if (this.tabs.index < 0)
					this.tabs.index = maxTabPos;

				if (this.tabs.index > maxTabPos / 2)
					this.scrollRight(1000);
				else if (this.tabs.index < maxTabPos / 2)
					this.scrollLeft(1000);
			},
			tabMatches(index) {
				return this.tabs.index === index;
			},
			calculateFilterStrip() {
				this.form.filterStripLength = Math.round(Number(this.page.filterStripCalc.area) * 43560 / 30);
				this.form.filterStripWidth = 30;
				this.$v.form.filterStripLength.$touch();
				this.$v.form.filterStripWidth.$touch();
				this.page.filterStripCalc.show = false;
			},
			calculateRiparian() {
				this.form.riparianBufferLength = Math.round(Number(this.page.riparianCalc.area) * 43560 / 50);
				this.form.riparianBufferWidth = 50;
				this.$v.form.riparianBufferLength.$touch();
				this.$v.form.riparianBufferWidth.$touch();
				this.page.riparianCalc.show = false;
			},
			checkAltWaterSupply(val) {
				if (val) this.form.isAlternativeWaterSupplyEnabled = true;
			},
			sumEffort(array, prop) {
				let total = 1;
				for (let obj of array) {
					total = total * (1 - (Number(obj[prop]) / 100));
				}
				return (1 - total) * 100;
			}
		},
		computed: {
			implementationName() {
				let val = this.form.implementationStatus;
				let match = this.options.statuses.find(el => el.value === val);
				if (!this.isNullOrEmpty(match)) {
					return match.text;
				}
				return 'Undefined';
			}
		}
	}
</script>
