<template>
	<b-container class="py-4">
		<auth-container v-if="$route.name === 'PlanList'" :page="page">
			<h2 class="mb-4">Plans</h2>

			<grid-view api-url="plans" use-filter
					   :fields="table.fields"
					   :default-sort="table.sort" :default-reverse="table.reverse" :default-per-page="table.itemsPerPage"
					   collection-description="plans" show-create
					   item-name="plan"
					   custom-empty-message="You don't have any plans yet."></grid-view>

			<hr class="my-5" />
			<footer-data></footer-data>
		</auth-container>
		<router-view></router-view>
	</b-container>
</template>

<script>
	import GridView from '@/components/helpers/GridView';
	import moment from 'moment';

	export default {
		name: 'PlanList',
		components: {
			GridView
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: []
				},
				table: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'planNumber', sortable: true },
						{ key: 'producer', sortable: true },
						{ key: 'ran', sortable: true, formatter: (value) => { return value ? '✓' : '' }, tdClass: 'text-success font-weight-bold text-center', thClass: 'text-center' },
						{ key: 'county', sortable: true },
						{ key: 'farmDate', label: 'Plan Date', sortable: true, formatter: (value) => { return moment(value).format('M/D/YYYY') } },
						{ key: 'lastModified', sortable: true, formatter: (value) => { return moment(value).format('M/D/YYYY h:mm a') } }
					],
					sort: 'lastModified',
					reverse: true,
					itemsPerPage: 10
				}
			}
		}
	}
</script>