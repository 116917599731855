<template>
	<div :class="'map-container ' + (page.customWidth? '' : (page.customHeight ? 'w-100' : 'full-map'))" :style="(page.customHeight ? `height: ${height};` : '') + (page.customWidth ? `width: ${width};` : '')">
		<l-map :ref="refName" style="height: 100%; width:100%" :zoom="map.zoom" :center="map.center" :bounds="map.bounds" @mousemove="mapMove">
			<l-control-layers v-if="showLayerControl" position="topright"></l-control-layers>
			<l-tile-layer v-for="tileProvider in map.tileProviders"
						  :key="tileProvider.name"
						  :name="tileProvider.name"
						  :visible="tileProvider.visible"
						  :url="tileProvider.url"
						  :attribution="tileProvider.attribution"
						  layer-type="base" />
			<l-layer-group layer-type="overlay" name="Subbasins">
				<l-geo-json :geojson="map.geojson" :options="mapOptions" :options-style="mapStyle"></l-geo-json>
			</l-layer-group>
			<l-control position="bottomleft" v-if="height == 0">
				<div v-if="map.currentCoords !== undefined">Cursor position: {{map.currentCoords.lat | number(3)}}, {{map.currentCoords.lng | number(3)}}</div>
			</l-control>
		</l-map>
		<div v-if="page.loading" class="map-loading-overlay">
			<font-awesome-icon :icon="['fas', 'spinner']" spin size="3x" />
		</div>
	</div>
</template>

<script>
	import { LMap, LTileLayer, LGeoJson, LControl, LControlLayers, LLayerGroup } from 'vue2-leaflet';
	import 'leaflet/dist/leaflet.css';
	import counties from '@/components/txcounties.json';
	var bbox = require('geojson-bbox');

	var __globalSelectedCounties = [];

	export default {
		name: 'CountyMap',
		components: {
			LMap, LTileLayer, LGeoJson, LControl, LControlLayers, LLayerGroup
		},
		props: {
			showLayerControl: {
				type: Boolean,
				default: false
			},
			height: {
				type: String,
				default: '100%'
			},
			width: {
				type: String,
				default: '100%'
			},
			highlightSelected: {
				type: Boolean,
				default: false
			},
			selectMultiple: {
				type: Boolean,
				default: false
			},
			initialCounties: {
				type: Array,
				default: () => []
			},
			refName: {
				type: String,
				default: 'leafmap'
			}
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					customHeight: this.height != '100%',
					customWidth: this.width != '100%'
				},
				map: {
					zoom: 5,
					center: [31.840233, -99.785157],
					bounds: undefined,
					url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
					attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
					tileProviders: [
						{
							name: 'Default map',
							visible: true,
							attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
							url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
						},
						{
							name: 'Topography map',
							visible: false,
							url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
							attribution: 'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
						},
						{
							name: 'Satellite map',
							visible: false,
							url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
							attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
						}
					],
					geojson: counties,
					currentCoords: undefined
				},
				selectedCounties: this.initialCounties
			}
		},
		async mounted() {
			this.renderMap();
		},
		watch: {
			initialCounties(newVal) {
				this.selectedCounties = newVal;
				__globalSelectedCounties = this.selectedCounties;
			}
		},
		methods: {
			mapMove(e) {
				this.map.currentCoords = e.latlng;
			},
			mapClick(e) {
				var selection = e.target.feature.properties;
				var item = selection.NAME;

				if (this.highlightSelected) {
					if (this.selectMultiple) {
						var index = this.selectedCounties.findIndex(x => x == item);
						if (index === -1) {
							this.selectedCounties.push(item);
						} else {
							this.removeSelected(item);
						}
					} else {
						this.selectedCounties = [item];
					}
					__globalSelectedCounties = this.selectedCounties;
				}

				this.$emit('map-click', this.selectedCounties);
			},
			removeSelected(item) {
				if (this.highlightSelected) {
					this.selectedCounties = this.selectedCounties.filter(function (el) { return el != item; });
					__globalSelectedCounties = this.selectedCounties;
				}
			},
			renderMap: function() {
				window.dispatchEvent(new Event('resize'));
				var ref = this.$refs[this.refName];
				var self = this;
				this.$nextTick(function () {
					ref.mapObject.invalidateSize(true);
					self.setMapBounds();
				});
			},
			setMapBounds() {
				this.map.bounds = bbox(this.map.geojson);
			}
		},
		computed: {
			mapOptions() {
				return {
					onEachFeature: this.mapOnEachFeature
				};
			},
			mapStyle() {
				__globalSelectedCounties = this.selectedCounties;

				return (feature) => {
					var fillColor = '#e4fabe';
					var index = __globalSelectedCounties.findIndex(x => x == feature.properties.NAME);
					if (index > -1)
						fillColor = '#6fedda';

					return {
						fillColor: fillColor,
						weight: 1,
						opacity: 0.8,
						color: 'black',
						dashArray: '3',
						fillOpacity: 0.8
					};
				};
			},
			mapOnEachFeature() {
				return (feature, layer) => {
					layer.bindTooltip(
						'<div>' + feature.properties.NAME + ' County</div>',
						{ permanent: false, sticky: true });

					layer.on({
						click: this.mapClick
					})
				}
			}
		}
	}
</script>