import Vue from 'vue';
import { mapGetters } from 'vuex';
import moment from 'moment';

Vue.mixin({
	data() {
		return {
			publicPath: process.env.BASE_URL,
			contactEmail: 'eco.web@tamu.edu',
			roleNames: {
				user: 'User',
				admin: 'Admin',
				superAdmin: 'SuperAdmin'
			}
		}
	},
	watch: {
		'$route'() {
			var storedToken = localStorage.getItem('auth_token');
			if (!storedToken || (storedToken !== this.token)) this.$store.commit('logout');
		}
	},
	methods: {
		getTokenHeader() {
			return {
				headers: { 'Authorization': 'Bearer ' + localStorage.getItem('auth_token') }
			};
		},
		isAuthorized(role) {
			return this.isAuthenticated && this.user.emailConfirmed && this.roles.includes(role);
		},
		logout(redirect = true) {
			localStorage.removeItem('auth_token');
			this.$store.commit('logout');
			if (redirect)
				this.$router.push('/').catch(err => { this.log(err) });
		},
		isApiUnauthorized(error) {
			return error.response && (error.response.status === 401 || error.response.status === 403);
		},
		isDevMode() {
			return process.env.NODE_ENV === 'development';
		},
		isNullOrEmpty(value) {
			return value === undefined || value === null || value === '';
		},
		capitalizeFirstLette(s) {
			return s.charAt(0).toUpperCase() + s.slice(1);
		},
		numberWithCommas(x) {
			var parts = x.toString().split(".");
			parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			return parts.join(".");
		},
		numberFixed(num, decimals) {
			if (this.isNullOrEmpty(num)) return num;
			return num.toFixed(decimals);
		},
		toDate(value, format = 'llll') {
			if (value) {
				return moment(String(value)).format(format);
			}
		},
		roundNumber(num) {
			return Math.round((num + Number.EPSILON) * 100) / 100;
		},
		sum(array, prop) {
			let total = 0;
			for (let obj of array) {
				total += Number(obj[prop]);
			}
			return total;
		},
		log(message) {
			if (this.isDevMode()) {
				console.log(message);
			}
		},
		logError(error, defaultMessage = undefined) {
			var messages = [];
			if (defaultMessage) {
				messages.push(defaultMessage);
			}

			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				console.log(error.response.data);
				//console.log(error.response.status);
				//console.log(error.response.headers);

				if (error.response.status === 401 || error.response.status === 403) {
					messages.push('Invalid credentials. Either your user name or password is incorrect, or you do not have permission to view this page.');
				} else if (error.response.status === 404) {
					messages.push('Requested data not found. Please check the URL in your browser and make sure you have the correct ID numbers if any.');
				} else if (error.response.data && error.response.data.errors) {
					var errors = error.response.data.errors;
					for (var key in errors) {
						for (var j = 0; j < errors[key].length; j++) {
							messages.push(errors[key][j]);
						}
					}
				} else if (error.response.data) {
					if (typeof error.response.data === 'string' || error.response.data instanceof String)
						messages.push(error.response.data);
					else
						messages.push('There was an error processing your request.');
				}

				if (error.response.data.stackTrace) {
					console.log(error.response.data.stackTrace);
				}
			} else if (error.request) {
				// The request was made but no response was received
				// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
				// http.ClientRequest in node.js
				console.log(error.request);
			} else {
				// Something happened in setting up the request that triggered an Error
				console.log('Error', error.message);
			}
			//console.log(error.config);

			return messages;
		},
		errorContainsKey(error, key) {
			return error.response && error.response.data && error.response.data.errors && Object.prototype.hasOwnProperty.call(error.response.data.errors, key); //error.response.data.errors.hasOwnProperty(key);
		},
		convertDdToDms(D) {
			return {
				deg: 0 | (D < 0 ? (D = -D) : D),
				min: 0 | (((D += 1e-9) % 1) * 60),
				sec: (0 | (((D * 60) % 1) * 6000)) / 100,
			};
		}
	},
	computed: {
		...mapGetters(['isAuthenticated', 'user', 'roles', 'token']),
		localStorageToken() {
			return localStorage.getItem('auth_token');
		},
		getValidState() {
			return (prop) => {
				return prop.$dirty ? !prop.$error : null;
			}
		},
		requiredFeedback() {
			return (prop) => {
				if (!prop.required) return 'Required';
			}
		}
	}
})