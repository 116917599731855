<template>
	<field-form :plan-id="planId" :county="$parent.plan.county" :plan-number="$parent.plan.planNumber"></field-form>
</template>

<script>
	import FieldForm from '@/views/plans/fields/FieldForm';

	export default {
		name: 'FieldCreate',
		components: {
			FieldForm
		},
		data() {
			return {
				planId: this.$route.params.id
			}
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			get() {
				this.planId = this.$route.params.id;
			}
		}
	}
</script>