<template>
	<div>
		<div style="height:100px"></div>
		<div :class="!fixedWidth ? 'fixed-bottom' : 'fixed-bottom bg-light border-top'">
			<b-container :fluid="!fixedWidth" :class="!fixedWidth ? 'p-0' : 'py-0'">
				<b-row no-gutters>
					<b-col :md="cols" :offset-md="offset" :lg="colsLg" :offset-lg="offsetLg" :class="fixedWidth ? '' : 'bg-light border-top'">
						<div :class="fixedWidth ? 'py-3 d-flex align-items-center' : 'py-3 d-flex align-items-center px-3 px-md-4'">
							<slot></slot>
						</div>
					</b-col>
				</b-row>
			</b-container>
		</div>
	</div>
</template>

<script>
export default {
	name: 'FixedActionBar',
	props: {
		cols: {
			type: Number,
			default: 9
		},
		offset: {
			type: Number,
			default: 3
		},
		colsLg: {
			type: Number,
			default: 9
		},
		offsetLg: {
			type: Number,
			default: 3
		},
		fixedWidth: {
			type: Boolean,
			default: false
		}
	}
}
</script>
