<template>
	<b-container>
		<div class="p-5">
			<b-jumbotron class="py-5 text-center">
				<p>
					TBET is a web-based tool for predicting sediment and nutrient losses from agricultural fields under a variety of conservation practices using the Soil and Water Assessment Tool (SWAT).
				</p>
				<p class="mb-0">
					TBET was developed by the Agricultural Research Service (USDA) in conjunction with the Texas State Soil and Water Conservation Board (TSSWCB).   This software is a direct descendant of the PPM Calculator which was developed by the Division of Agricultural Sciences and Natural Resources at Oklahoma State University, in the Biosystems and Agricultural Engineering and Plant and Soil Science Departments.
				</p>
			</b-jumbotron>

			<footer-data></footer-data>
		</div>
	</b-container>
</template>

<script>
	export default {
		name: 'Home'
	}
</script>
