import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '../views/Home.vue';
import Help from '../views/Help.vue';

import AdminDashboard from '../views/admin/AdminDashboard.vue';
import AdminPlans from '../views/admin/AdminPlans.vue';
import AdminErrorList from '../views/admin/errors/AdminErrorList.vue';
import AdminErrorView from '../views/admin/errors/AdminErrorView.vue';

import UserList from '../views/admin/users/UserList.vue';
import UserCreate from '../views/admin/users/UserCreate.vue';
import UserEdit from '../views/admin/users/UserEdit.vue';

import PlanList from '../views/plans/PlanList.vue';
import PlanCreate from '../views/plans/PlanCreate.vue';
import PlanEdit from '../views/plans/PlanEdit.vue';
import PlanView from '../views/plans/PlanView.vue';

import FieldCreate from '../views/plans/fields/FieldCreate.vue';
import FieldEdit from '../views/plans/fields/FieldEdit.vue';
import FieldView from '../views/plans/fields/FieldView.vue';

import ImplementationCreate from '../views/plans/fields/implementations/ImplementationCreate.vue';
import ImplementationEdit from '../views/plans/fields/implementations/ImplementationEdit.vue';

import AccountProfile from '../views/account/AccountProfile.vue';
import AccountReset from '../views/account/AccountReset.vue';
import AccountChangeEmail from '../views/account/AccountChangeEmail.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/', name: 'Home', component: Home
    },
    {
        path: '/help', name: 'Help', component: Help
    },
    {
        path: '/account', name: 'AccountProfile', component: AccountProfile, meta: { title: 'Account' },
        children: [
            {
                path: 'reset/:code', name: 'AccountReset', component: AccountReset, meta: { title: 'Reset Password' }
            },
            {
                path: 'changeemail/:userId/:code/:email', name: 'AccountChangeEmail', component: AccountChangeEmail, meta: { title: 'Change Email' }
            }
        ]
    },
	{
        path: '/admin', name: 'AdminDashboard', component: AdminDashboard, meta: { title: 'Admin Dashboard' },
        children: [
            {
                path: 'users', name: 'UserList', component: UserList, meta: { title: 'Users' },
                children: [
                    {
                        path: 'create', name: 'UserCreate', component: UserCreate, meta: { title: 'Create' }
                    },
                    {
                        path: ':id', name: 'UserEdit', component: UserEdit, meta: { title: 'Edit' }
                    }
                ]
            },
            {
                path: 'plans', name: 'AdminPlans', component: AdminPlans, meta: { title: 'Plans' }
            },
            {
                path: 'errors', name: 'AdminErrorList', component: AdminErrorList, meta: { title: 'Error Reports' },
                children: [
                    {
                        path: ':id', name: 'AdminErrorView', component: AdminErrorView, meta: { title: 'Edit' }
                    }
                ]
            }
        ]
    },
    {
        path: '/plans', name: 'PlanList', component: PlanList, meta: { title: 'Plans' },
        children: [
            {
                path: 'create', name: 'PlanCreate', component: PlanCreate, meta: { title: 'Create' }
            },
            {
                path: ':id', name: 'PlanView', component: PlanView, meta: { title: 'View' },
                children: [
                    {
                        path: 'edit', name: 'PlanEdit', component: PlanEdit, meta: { title: 'Edit' }
                    },
                    {
                        path: 'fields/create', name: 'FieldCreate', component: FieldCreate, meta: { title: 'Create' }
                    },
                    {
                        path: 'fields/:fieldId', name: 'FieldView', component: FieldView, meta: { title: 'View' },
                        children: [
                            {
                                path: 'edit', name: 'FieldEdit', component: FieldEdit, meta: { title: 'Edit' }
                            },
                            {
                                path: 'implementations/create', name: 'ImplementationCreate', component: ImplementationCreate, meta: { title: 'Create' }
                            },
                            {
                                path: 'implementations/:implementationId', name: 'ImplementationEdit', component: ImplementationEdit, meta: { title: 'Edit' }
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        path: '*', redirect: '/plans'
    }
];

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes
});

export default router
