<template>
	<auth-container :page="page">
		<h2 class="mb-4">
			<router-link to="/plans" class="text-reset text-decoration-none mr-2">Plans</router-link>
			<font-awesome-icon :icon="['fas', 'angle-right']" class="text-gray-400 mr-2" />
			<router-link :to="`/plans/${planId}`" class="text-reset text-decoration-none mr-2">{{planNumber}}</router-link>
			<font-awesome-icon :icon="['fas', 'angle-right']" class="text-gray-400" />
			<span v-if="isUpdate">
				<router-link :to="`/plans/${planId}/fields/${fieldId}`" class="text-reset text-decoration-none mr-2 ml-2">{{form.name}}</router-link>
				<font-awesome-icon :icon="['fas', 'angle-right']" class="text-gray-400" />
			</span>
			{{isUpdate ? 'Edit' : 'New field'}}
		</h2>
		<error-list :errors="page.saveErrors"></error-list>

		<b-toast id="changes-saved" variant="success" solid title="Changes saved" toaster="b-toaster-top-center">
			Your changes have been saved.
		</b-toast>

		<div>
			<b-form @submit.prevent="save">
				<b-form-group label="Field name" :invalid-feedback="requiredFeedback($v.form.name)">
					<b-form-input v-model.trim="$v.form.name.$model" type="text" :state="getValidState($v.form.name)"></b-form-input>
				</b-form-group>

				<b-row>
					<b-col md>
						<b-form-group label="Area (acres)" :invalid-feedback="requiredFeedback($v.form.area)">
							<b-form-input v-model.number.trim="$v.form.area.$model" type="number" step="any" :state="getValidState($v.form.area)"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col md>
						<b-form-group label="Distance to stream (ft)" description="Enter 0 if the field contains or borders stream" :invalid-feedback="requiredFeedback($v.form.streamDistance)">
							<b-form-input v-model.number.trim="$v.form.streamDistance.$model" type="number" step="any" :state="getValidState($v.form.streamDistance)"></b-form-input>
						</b-form-group>
					</b-col>
				</b-row>

				<h2>Add up to 3 soils for the field</h2>

				<div v-if="form.soils.length > 0">
					<table class="table table-sm mb-3 border-bottom table-valign-m auto-width">
						<thead class="thead-dark">
							<tr>
								<th>% of Field</th>
								<th>Soil</th>
								<th>Slope %</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(soil, i) in form.soils" :key="i">
								<td><b-form-input type="number" step="any" class="number-field" v-model.number.trim="$v.form.soils.$each[i].fieldPercentage.$model" :state="getValidState($v.form.soils.$each[i].fieldPercentage)"></b-form-input></td>
								<td>{{getSoilName(soil.soilId)}}</td>
								<td><b-form-input type="number" step="any" class="number-field" v-model.number.trim="$v.form.soils.$each[i].slopePercentage.$model" :state="getValidState($v.form.soils.$each[i].slopePercentage)"></b-form-input></td>
								<td>
									<button type="button" class="plain text-danger mx-2" v-b-tooltip.hover.right="'Click to remove soil'" @click="removeSoil(soil.soilId)">
										<font-awesome-icon :icon="['fas', 'times']" />
									</button>
								</td>
							</tr>
						</tbody>
					</table>
					<p>
						Note: field percentage should add up to 100.
						<a href="#" @click.prevent="forceSoilPercentage" class="underline"
						   v-b-tooltip.hover title="Force soil fractions to add to 100% using the listed proportions.">
							Force to 100%.
						</a>
					</p>
				</div>

				<p>
					<b-button v-if="form.soils.length < 3" variant="info" @click="page.soils.show = true" type="button">Add a soil</b-button>
				</p>


				<fixed-action-bar :cols-lg="12" :offset-lg="0" :cols="12" :offset="0" fixed-width>
					<b-button type="submit" class="mr-2" :disabled="!$v.$anyDirty" variant="success">
						<font-awesome-icon v-if="page.saving" :icon="['fas', 'spinner']" spin />
						Save
					</b-button>
					<parent-button back-to="plan" :to="isUpdate ? '../..' : '..'"></parent-button>
				</fixed-action-bar>
			</b-form>
		</div>

		<b-modal v-model="page.soils.show" title="Select soil" size="xl" scrollable>
			<error-list :errors="page.soils.errors"></error-list>

			<p>
				Click on a row in the table, then click the "Add selected soil" button. 
				Use the text box below to find soils by county or name.
				Remove text from the box to view all soils.
			</p>

			<grid-view api-url="soils" use-filter small :default-filter="county" 
					   :fields="options.soils.fields" :default-per-page="50"
					   collection-description="soils"
					   item-name="soil"
					   default-sort="description"
					   selectable @selectionChanged="soilSelectionChanged"></grid-view>

			<div slot="modal-footer">
				<b-button v-if="options.soils.currentSelection !== null" type="button" variant="success" @click="appendSelectedSoil">Add selected soil</b-button>
				<b-button type="button" variant="secondary" @click="page.soils.show = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</auth-container>
</template>

<script>
	import { required, decimal } from 'vuelidate/lib/validators';
	import GridView from '@/components/helpers/GridView';

	export default {
		name: 'FieldForm',
		components: {
			GridView
		},
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			planId: {
				type: String,
				default: null
			},
			fieldId: {
				type: String,
				default: null
			},
			county: {
				type: String,
				default: null
			},
			planNumber: {
				type: String,
				default: null
			}
		},
		data() {
			return {
				page: {
					id: this.fieldId,
					errors: [],
					loading: false,
					showLogin: false,
					saving: false,
					saveErrors: [],
					soils: {
						show: false,
						loading: false,
						errors: []
					}
				},
				form: {
					planId: Number(this.planId),
					name: null,
					area: 0,
					streamDistance: 0,
					soils: []
				},
				options: {
					soils: {
						fields: [
							'description', 'name', 'symbol', 'slope',
							{ key: 'slopeLength', formatter: (value) => { return Math.ceil(value) } },
							'soilKey', 'area'
						],
						currentSelection: null,
						currentName: null,
						nameMatches: []
					}
				},
			}
		},
		validations: {
			form: {
				name: { required },
				area: { required, decimal },
				streamDistance: { required, decimal },
				soils: {
					$each: {
						fieldId: { required },
						soilId: { required },
						fieldPercentage: { required, decimal },
						slopePercentage: { required, decimal }
					}
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				let url = this.isUpdate ? `fields/${this.fieldId}` : `fields/recent/${this.planId}`;
				try {
					const response = await this.$http.get(url, this.getTokenHeader());
					this.log(response.data);
					this.form = response.data.field;
					if (this.form.soils === null) this.form.soils = [];
					if (response.data.soilNames !== null) this.options.soils.nameMatches = response.data.soilNames;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;
				this.$v.form.$touch();

				if (this.form.soils.length < 1) {
					this.page.saveErrors.push('Please add at least one soil.');
				} else if (this.$v.$invalid) {
					this.log(this.$v);
					this.page.saveErrors.push('Please fix the errors below and try again.');
				} else {
					this.log(this.form);
					try {
						if (!this.isUpdate) {
							this.form.id = 0;
							const response = await this.$http.post('fields', this.form, this.getTokenHeader());
							this.$router.push({ name: 'FieldView', params: {  id: this.planId, fieldId: response.data.toString() } }).catch(err => { this.log(err); });
						} else {
							await this.$http.put(`fields/${this.fieldId}`, this.form, this.getTokenHeader());
						}

						this.$bvToast.show('changes-saved');
						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			forceSoilPercentage() {
				let total = 0;
				for (let soil of this.form.soils) {
					total += Number(soil.fieldPercentage);
				}

				if (total > 0) {
					for (let soil of this.form.soils) {
						soil.fieldPercentage = this.roundNumber(soil.fieldPercentage / total * 100);
					}
				}
				else if (total <= 0) {
					this.form.soils[0].fieldPercentage = 100;
				}
			},
			soilSelectionChanged(items) {
				this.log(items);
				if (items.length > 0) {
					let item = items[0];
					this.options.soils.currentSelection = {
						fieldId: this.isNullOrEmpty(this.fieldId) ? 0 : Number(this.fieldId),
						soilId: item.id,
						fieldPercentage: 0,
						slopePercentage: item.slope
					}
					this.options.soils.currentName = {
						id: item.id,
						name: item.name
					};
				} else {
					this.options.soils.currentSelection = null;
					this.options.soils.currentName = null;
				}
			},
			appendSelectedSoil() {
				if (this.form.soils.length < 3 && this.options.soils.currentSelection !== null) {
					if (this.form.soils.length === 0) this.options.soils.currentSelection.fieldPercentage = 100;
					this.form.soils.push(this.options.soils.currentSelection);
					this.options.soils.nameMatches.push(this.options.soils.currentName);
					this.$v.form.soils.$touch();
				}
				this.page.soils.show = false;
			},
			getSoilName(id) {
				let match = this.options.soils.nameMatches.find(el => el.id === id);
				if (!this.isNullOrEmpty(match)) {
					return match.name;
				}
				return 'Undefined';
			},
			removeSoil(id) {
				this.form.soils = this.form.soils.filter(function (el) { return el.soilId != id; });
				this.$v.form.soils.$touch();
			}
		}
	}
</script>
