const signalR = require("@microsoft/signalr");

export default {
	install(Vue) {
		const connection = new signalR.HubConnectionBuilder()
			.withUrl('/status-hub')
			//.withHubProtocol(new signalR.protocols.msgpack.MessagePackHubProtocol())
			.configureLogging(signalR.LogLevel.Debug)
			.build();

		const statusHub = new Vue();

		// Forward hub events through the event, so we can listen for them in the Vue components
		connection.on('PlanStatusChange', (planId, progress, statusMessage) => {
			statusHub.$emit('plan-status-changed', { planId, progress, statusMessage })
		});

		// Provide methods for components to send messages back to server
		// Make sure no invocation happens until the connection is established
		statusHub.planOpened = (planId) => {
			//if (!startedPromise) return;
			return startedPromise
				.then(() => connection.invoke('JoinPlanGroup', Number(planId)))
				.catch(console.error);
		}
		statusHub.planClosed = (planId) => {
			//if (!startedPromise) return;
			return startedPromise
				.then(() => connection.invoke('LeavePlanGroup', Number(planId)))
				.catch(console.error);
		}

		Vue.prototype.$statusHub = statusHub;

		// You need to call connection.start() to establish the connection
		// the client wont handle reconnecting for you! Docs recommend listening onclose
		// and handling it there. This is the simplest of the strategies
		let startedPromise = null;
		function start() {
			startedPromise = connection.start()
				.catch(err => {
					console.error('Failed to connect with hub', err)
					return new Promise((resolve, reject) => setTimeout(() => start().then(resolve).catch(reject), 5000))
				});
			return startedPromise;
		}
		connection.onclose(() => start());

		start();
	}
}
