<template>
	<b-button variant="secondary" type="button" :to="to" :class="cssClass">
		{{ isNullOrEmpty(backTo) ? text : 'Back to ' + backTo }}
	</b-button>
</template>

<script>
export default {
	name: 'ParentButton',
	props: {
		backTo: {
			type: String,
			default: null
		},
		text: {
			type: String,
			default: 'Back'
		},
		to: {
			type: String,
			default: '..'
		},
		cssClass: {
			type: String,
			default: 'mr-2'
		}
	}
}
</script>
