<template>
	<field-form is-update :plan-id="planId" :field-id="fieldId"
		 :county="$parent.$parent.plan.county" :plan-number="$parent.$parent.plan.planNumber"></field-form>
</template>

<script>
	import FieldForm from '@/views/plans/fields/FieldForm';

	export default {
		name: 'FieldEdit',
		components: {
			FieldForm
		},
		data() {
			return {
				planId: this.$route.params.id,
				fieldId: this.$route.params.fieldId
			}
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			get() {
				this.planId = this.$route.params.id;
				this.fieldId = this.$route.params.fieldId;
			}
		}
	}
</script>