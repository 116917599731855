import Vue from 'vue';

import PageLoading from '@/components/helpers/PageLoading';
import ErrorList from '@/components/helpers/ErrorList';
import SaveButton from '@/components/helpers/SaveButton';
import BackButton from '@/components/helpers/BackButton';
import ParentButton from '@/components/helpers/ParentButton';
import SuccessAlert from '@/components/helpers/SuccessAlert';
import FixedActionBar from '@/components/helpers/FixedActionBar';
import AuthContainer from '@/components/helpers/AuthContainer';
import LoginForm from '@/components/LoginForm';
import FooterData from '@/components/FooterData';

Vue.component('page-loading', PageLoading);
Vue.component('error-list', ErrorList);
Vue.component('save-button', SaveButton);
Vue.component('back-button', BackButton);
Vue.component('parent-button', ParentButton);
Vue.component('success-alert', SuccessAlert);
Vue.component('fixed-action-bar', FixedActionBar);
Vue.component('auth-container', AuthContainer);
Vue.component('login-form', LoginForm);
Vue.component('footer-data', FooterData);
