<template>
	<div>
		<auth-container v-if="$route.name === 'FieldView'" :page="page">
			<h2 class="mb-4">
				<router-link to="/plans" class="text-reset text-decoration-none mr-2">Plans</router-link>
				<font-awesome-icon :icon="['fas', 'angle-right']" class="text-gray-400 mr-2" />
				<router-link :to="`/plans/${planId}`" class="text-reset text-decoration-none mr-2">{{$parent.plan.planNumber}}</router-link>
				<font-awesome-icon :icon="['fas', 'angle-right']" class="text-gray-400" />
				{{field.name}}
			</h2>

			<b-row>
				<b-col md>
					<table class="table table-definitions">
						<tbody>
							<tr>
								<th>Field name:</th>
								<td>{{field.name}}</td>
							</tr>
							<tr>
								<th>Area:</th>
								<td>{{field.area | number}} acres</td>
							</tr>
							<tr v-if="field.streamDistance === 0">
								<th>Stream:</th>
								<td>Field contains/borders stream</td>
							</tr>
							<tr v-else>
								<th>Distance to stream:</th>
								<td>{{field.streamDistance | number}} ft</td>
							</tr>
						</tbody>
					</table>
				</b-col>
				<b-col md>
					<table class="table table-definitions">
						<tbody>
							<tr>
								<th>Soil:</th>
								<td>
									<div v-for="(soil, i) in field.soils" :key="i">
										{{soil.fieldPercentage | number(2)}}%
										{{getSoilName(soil.soilId)}}
										(slope {{soil.slopePercentage | number(2)}}%)
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</b-col>
			</b-row>

			<hr class="mt-0 mb-4" />

			<h2 class="mb-3">Implementations</h2>

			<table class="table-list mb-3" v-if="implementations.length > 0">
				<tbody>
					<tr v-for="(imp, i) in implementations" :key="i" @click="goToImplementation(imp.id)">
						<td><b>{{imp.description}}</b></td>
						<td>{{imp.cropName}} / {{imp.region}}</td>
						<td class="text-right font-italic">Last saved {{imp.lastModified | date}}</td>
					</tr>
				</tbody>
			</table>

			<p v-if="implementations.length < 6">
				<b-button v-if="implementations.length < 1" variant="info" to="implementations/create" append>Create new implementation</b-button>
				<save-button v-else type="button" variant="info" @click.native="copyImp" :saving="page.copyImp.saving" text="Create new implementation"></save-button>
			</p>

			<fixed-action-bar :cols-lg="12" :offset-lg="0" :cols="12" :offset="0" fixed-width>
				<b-button type="button" variant="info" to="edit" append class="mr-2">Edit field</b-button>
				<parent-button back-to="plan"></parent-button>
				<b-button type="button" @click="page.copy.show = true" :disabled="page.saving" variant="warning" class="ml-auto mr-2">Copy</b-button>
				<b-button variant="danger" @click="page.delete.show = true">Delete</b-button>
			</fixed-action-bar>

			<b-modal v-model="page.copy.show" title="Copy field">
				<error-list :errors="page.copy.errors"></error-list>

				<p>
					Click the button below to copy this field.
					All implementations for this field will also be copied. 
				</p>

				<b-form-group label="New field name">
					<b-form-input v-model="page.copy.name" type="text" required></b-form-input>
				</b-form-group>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.copy.saving" @click.native="copy" text="Copy Field" variant="success" />
					<b-button type="button" variant="secondary" @click="page.copy.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>

			<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.delete.errors"></error-list>

				<p>
					Are you sure you want to delete this field?
					This action is permanent and cannot be undone.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		name: 'FieldView',
		data() {
			return {
				planId: this.$route.params.id,
				fieldId: this.$route.params.fieldId,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					delete: {
						show: false,
						errors: [],
						saving: false
					},
					copy: {
						show: false,
						errors: [],
						saving: false,
						name: null
					},
					copyImp: {
						errors: [],
						saving: false
					}
				},
				field: {},
				soilNames: [],
				implementations: []
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.planId = this.$route.params.id;
				this.fieldId = this.$route.params.fieldId;
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`fields/${this.fieldId}`, this.getTokenHeader());
					this.log(response.data);

					this.field = response.data.field;
					this.soilNames = response.data.soilNames;
					this.implementations = response.data.implementations;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			getSoilName(id) {
				let match = this.soilNames.find(el => el.id === id);
				if (!this.isNullOrEmpty(match)) {
					return match.name;
				}
				return 'Undefined';
			},
			goToImplementation(id) {
				this.$router.push({ name: 'ImplementationEdit', params: { id: this.planId, fieldId: this.fieldId, implementationId: id.toString() } }).catch(err => { this.log(err); });
			},
			async copyImp() {
				this.page.copyImp.errors = [];
				this.page.copyImp.saving = true;

				if (this.implementations.length > 0) {
					try {
						let copyId = this.implementations[this.implementations.length - 1].id;
						const response = await this.$http.post(`implementations/copy/${copyId}`, {}, this.getTokenHeader());
						this.$router.push({ name: 'ImplementationEdit', params: { id: this.planId, fieldId: this.fieldId, implementationId: response.data.toString() } }).catch(err => { this.log(err); });
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.copyImp.errors = this.logError(error);
					}
				}

				this.page.copyImp.saving = false;
			},
			async copy() {
				this.page.copy.errors = [];
				this.page.copy.saving = true;

				if (this.isNullOrEmpty(this.page.copy.name)) {
					this.page.copy.errors.push('Please enter a name for the new field.');
				} else {
					try {
						const response = await this.$http.post(`fields/copy/${this.fieldId}`, { value: this.page.copy.name }, this.getTokenHeader());
						this.page.copy.show = false;
						this.$router.push({ name: 'FieldView', params: { id: this.planId, fieldId: response.data.toString() } }).catch(err => { this.log(err); });
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.copy.errors = this.logError(error);
					}
				}

				this.page.copy.saving = false;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`fields/${this.fieldId}`, this.getTokenHeader());
					this.log(response.data);
					this.$router.push({ name: 'PlanView', params: { id: this.planId } }).catch(err => { this.log(err); });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			}
		}
	}
</script>