<template>
	<b-container class="py-4">
		<h2 class="mb-4">User guide</h2>

		<p>
			<b-button variant="info" :href="`${publicPath}data/TBET-User-Guide.pdf`" target="_blank">View User Guide</b-button>
		</p>

		<hr class="my-4" />

		<h2 class="mb-4">Guidance documents</h2>

		<ul class="ul-bordered">
			<li><a :href="`${publicPath}data/guides/fence.pdf`" target="_blank">Fence (382)</a></li>
			<li><a :href="`${publicPath}data/guides/water-storage.pdf`" target="_blank">Waste Storage Facility (313)</a></li>
			<li><a :href="`${publicPath}data/guides/nutrient.pdf`" target="_blank">Nutrient Management (590)</a></li>
			<li><a :href="`${publicPath}data/guides/pasture.pdf`" target="_blank">Pasture Planting (512)</a></li>
			<li><a :href="`${publicPath}data/guides/brush.pdf`" target="_blank">Brush Management (314)</a></li>
			<li><a :href="`${publicPath}data/guides/range.pdf`" target="_blank">Range Planting (550)</a></li>
			<li><a :href="`${publicPath}data/guides/field-border.pdf`" target="_blank">Field Border (386)</a></li>
			<li><a :href="`${publicPath}data/guides/crop-rotation.pdf`" target="_blank">Crop Rotation (328)</a></li>
		</ul>

		<hr class="my-5" />

		<footer-data></footer-data>
	</b-container>
</template>

<script>
	export default {
		name: 'Help'
	}
</script>
