<template>
	<div>
		<b-row>
			<b-col md>
				<img :src="`${publicPath}data/practices/${fileName}.png`" class="img-fluid" :alt="title" />
			</b-col>
			<b-col md>
				<p>
					{{text}}
				</p>
				<p v-if="fileName === 'forage'">
					Low residual = 1000 - 1300<br />
					Medium residual = 1300 - 1700<br />
					High residual = 1700 - 2000
				</p>

				<div>
					<b-button v-if="!noPdf && !multiplePdfs" type="button" :href="`${publicPath}data/practices/${fileName}.pdf`" target="_blank" variant="info">NRCS Standard</b-button>
					<b-dropdown v-if="multiplePdfs" variant="info" text="NRCS Standards" class="mr-2">
						<b-dropdown-item v-for="(pdf, i) in pdfs" :key="i" :href="`${publicPath}data/practices/${pdf.fileName}.pdf`" target="_blank">{{pdf.title}}</b-dropdown-item>
					</b-dropdown>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
export default {
	name: 'PracticeHelp',
	props: {
		fileName: {
			type: String,
			default: ''
		},
		title: {
			type: String,
			default: ''
		},
		text: {
			type: String,
			default: ''
		},
		noPdf: {
			type: Boolean,
			default: false
		},
		multiplePdfs: {
			type: Boolean,
			default: false
		},
		pdfs: {
			type: Array,
			default: () => []
		}
	},
	methods: {
		goBack: function() {
			this.$router.go(-1);
		}
	}
}
</script>
