<template>
	<implementation-form is-update :plan-id="planId" :field-id="fieldId" :implementation-id="implementationId"
						 :county="$parent.$parent.plan.county"
						 :plan-number="$parent.$parent.plan.planNumber"
						 :field-name="$parent.field.name"></implementation-form>
</template>

<script>
	import ImplementationForm from '@/views/plans/fields/implementations/ImplementationForm';

	export default {
		name: 'ImplementationEdit',
		components: {
			ImplementationForm
		},
		data() {
			return {
				planId: this.$route.params.id,
				fieldId: this.$route.params.fieldId,
				implementationId: this.$route.params.implementationId
			}
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			get() {
				this.planId = this.$route.params.id;
				this.fieldId = this.$route.params.fieldId;
				this.implementationId = this.$route.params.implementationId;
			}
		}
	}
</script>