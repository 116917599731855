<template>
	<plan-form></plan-form>
</template>

<script>
	import PlanForm from '@/views/plans/PlanForm';

	export default {
		name: 'PlanCreate',
		components: {
			PlanForm
		}
	}
</script>