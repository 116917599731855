<template>
	<implementation-form :plan-id="planId" :field-id="fieldId" 
		:county="$parent.$parent.plan.county" 
		:plan-number="$parent.$parent.plan.planNumber"
		:field-name="$parent.field.name"></implementation-form>
</template>

<script>
	import ImplementationForm from '@/views/plans/fields/implementations/ImplementationForm';

	export default {
		name: 'ImplementationCreate',
		components: {
			ImplementationForm
		},
		data() {
			return {
				planId: this.$route.params.id,
				fieldId: this.$route.params.fieldId
			}
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			get() {
				this.planId = this.$route.params.id;
				this.fieldId = this.$route.params.fieldId;
			}
		}
	}
</script>