<template>
	<auth-container :page="page">
		<h2 class="mb-4">
			<router-link to="/plans" class="text-reset text-decoration-none mr-2">Plans</router-link>
			<font-awesome-icon :icon="['fas', 'angle-right']" class="text-gray-400" />
			<span v-if="isUpdate">
				<router-link :to="`/plans/${page.id}`" class="text-reset text-decoration-none mr-2 ml-2">{{form.planNumber}}</router-link>
				<font-awesome-icon :icon="['fas', 'angle-right']" class="text-gray-400" />
			</span>
			{{isUpdate ? 'Edit' : 'New plan'}}
		</h2>
		<error-list :errors="page.saveErrors"></error-list>
		<success-alert ref="reportSubmittedAlert" text="Error report submitted." />

		<b-toast id="changes-saved" variant="success" solid title="Changes saved" toaster="b-toaster-top-center">
			Your changes have been saved.
		</b-toast>

		<div>
			<p class="text-muted mb-4 font-italic" v-if="status.hasRun" style="margin-top:-1.5rem">
				<small>Last run {{status.lastRunDate | dateFromNow}} in {{status.runTime}}.</small>
			</p>

			<b-form @submit.prevent="save">
				<b-row>
					<b-col md>
						<b-form-group label="Plan number">
							<b-form-input v-model.trim="$v.form.planNumber.$model" autofocus
										  list="plan-number-list"
										  type="text" :formatter="planNumberFormatter" :state="getValidState($v.form.planNumber)"></b-form-input>
							<b-form-invalid-feedback v-if="!$v.form.planNumber.required">Required</b-form-invalid-feedback>
							<b-form-invalid-feedback v-if="!$v.form.planNumber.planNumberFormat">Enter a plan number in the format: 999-99-9999</b-form-invalid-feedback>
						</b-form-group>
						<datalist id="plan-number-list">
							<option v-for="(pn, i) in options.planNumbers" :key="i">{{ pn }}</option>
						</datalist>

						<b-form-group label="Producer" :invalid-feedback="requiredFeedback($v.form.producer)">
							<b-form-input v-model.trim="$v.form.producer.$model" list="producer-list" type="text" :state="getValidState($v.form.producer)"></b-form-input>
						</b-form-group>
						<datalist id="producer-list">
							<option v-for="(pr, i) in options.producers" :key="i">{{ pr }}</option>
						</datalist>

						<b-button v-b-toggle.optional-data variant="primary" type="button">Optional Farm Data</b-button>
						<b-collapse id="optional-data">
							<b-card class="mt-3" bg-variant="light">
								<div class="mb-1">Location</div>
								<b-card no-body class="mb-3">
									<b-tabs card v-model="page.locationTabIndex">
										<b-tab title="Degrees/Minutes/Seconds" active @click="getLocation(true)">
											<b-card-text>
												<b-row>
													<b-col sm>
														<div class="mb-1">Latitude (N)</div>
														<b-input-group class="mb-2">
															<b-input-group-prepend is-text class="text-monospace">Degrees</b-input-group-prepend>
															<b-form-input v-model.number.trim="$v.locationFormat.lat.degrees.$model" type="number" required step="any" :state="getValidState($v.locationFormat.lat.degrees)"></b-form-input>
														</b-input-group>
														<b-input-group class="mb-2">
															<b-input-group-prepend is-text class="text-monospace">Minutes</b-input-group-prepend>
															<b-form-input v-model.number.trim="$v.locationFormat.lat.minutes.$model" type="number" required step="any" :state="getValidState($v.locationFormat.lat.minutes)"></b-form-input>
														</b-input-group>
														<b-input-group>
															<b-input-group-prepend is-text class="text-monospace">Seconds</b-input-group-prepend>
															<b-form-input v-model.number.trim="$v.locationFormat.lat.seconds.$model" type="number" required step="any" :state="getValidState($v.locationFormat.lat.seconds)"></b-form-input>
														</b-input-group>
													</b-col>
													<b-col sm>
														<div class="mb-1">Longitude (W)</div>
														<b-input-group class="mb-2">
															<b-input-group-prepend is-text class="text-monospace">Degrees</b-input-group-prepend>
															<b-form-input v-model.number.trim="$v.locationFormat.lon.degrees.$model" type="number" required step="any" :state="getValidState($v.locationFormat.lon.degrees)"></b-form-input>
														</b-input-group>
														<b-input-group class="mb-2">
															<b-input-group-prepend is-text class="text-monospace">Minutes</b-input-group-prepend>
															<b-form-input v-model.number.trim="$v.locationFormat.lon.minutes.$model" type="number" required step="any" :state="getValidState($v.locationFormat.lon.minutes)"></b-form-input>
														</b-input-group>
														<b-input-group>
															<b-input-group-prepend is-text class="text-monospace">Seconds</b-input-group-prepend>
															<b-form-input v-model.number.trim="$v.locationFormat.lon.seconds.$model" type="number" required step="any" :state="getValidState($v.locationFormat.lon.seconds)"></b-form-input>
														</b-input-group>
													</b-col>
												</b-row>
											</b-card-text>
										</b-tab>
										<b-tab title="Decimal Degrees" @click="getLocation(false)">
											<b-card-text>
												<b-row>
													<b-col sm>
														<b-form-group label="Latitude" :invalid-feedback="requiredFeedback($v.form.latitude)">
															<b-form-input v-model.number.trim="$v.form.latitude.$model" type="number" required step="any" :state="getValidState($v.form.latitude)"></b-form-input>
														</b-form-group>
													</b-col>
													<b-col sm>
														<b-form-group label="Longitude" :invalid-feedback="requiredFeedback($v.form.longitude)">
															<b-form-input v-model.number.trim="$v.form.longitude.$model" type="number" required step="any" :state="getValidState($v.form.longitude)"></b-form-input>
														</b-form-group>
													</b-col>
												</b-row>
											</b-card-text>
										</b-tab>
									</b-tabs>
								</b-card>

								<b-form-group label="HUC 12" :invalid-feedback="requiredFeedback($v.form.huc12)">
									<b-form-input v-model.trim="$v.form.huc12.$model" type="text" step="any" :state="getValidState($v.form.huc12)"></b-form-input>
								</b-form-group>

								<b-form-group label="Date" :invalid-feedback="requiredFeedback($v.form.farmDate)">
									<b-form-datepicker v-model.trim="$v.form.farmDate.$model" :state="getValidState($v.form.farmDate)"
													   locale="en" today-button reset-button close-button></b-form-datepicker>
								</b-form-group>

								<b-form-group label="Evaluator" :invalid-feedback="requiredFeedback($v.form.farmEvaluator)">
									<b-form-input v-model.trim="$v.form.farmEvaluator.$model" type="text" step="any" :state="getValidState($v.form.farmEvaluator)"></b-form-input>
								</b-form-group>

								<div class="mb-3">
									<div class="mb-2">Funding source</div>
									<b-list-group>
										<b-list-group-item><b-form-checkbox v-model.trim="$v.form.farmFunding319.$model">319</b-form-checkbox></b-list-group-item>
										<b-list-group-item><b-form-checkbox v-model.trim="$v.form.farmFunding503.$model">503</b-form-checkbox></b-list-group-item>
										<b-list-group-item>
											<b-form-group label="If other, please specify below" :invalid-feedback="requiredFeedback($v.form.farmFundingOther)">
												<b-form-input v-model.trim="$v.form.farmFundingOther.$model" type="text" step="any" :state="getValidState($v.form.farmFundingOther)"></b-form-input>
											</b-form-group>
										</b-list-group-item>
									</b-list-group>
								</div>

								<b-form-group label="Farm description:" :invalid-feedback="requiredFeedback($v.form.farmDescription)">
									<b-form-textarea v-model.trim="$v.form.farmDescription.$model" rows="3" max-rows="6" step="any" :state="getValidState($v.form.farmDescription)"></b-form-textarea>
								</b-form-group>
							</b-card>
						</b-collapse>
					</b-col>
					<b-col md>	
						<b-form-group label="Select a county from the list or click the map" :invalid-feedback="requiredFeedback($v.form.county)">
							<b-form-select v-model.trim="$v.form.county.$model" :options="options.counties" @change="switchCounty" :state="getValidState($v.form.county)">
								<template v-slot:first>
									<b-form-select-option :value="null" disabled>-- Select a county --</b-form-select-option>
								</template>
							</b-form-select>
						</b-form-group>

						<div class="mb-3">
							<county-map ref="countyMap" height="350px"
										:initial-counties="form.county === null ? [] : [form.county]"
										highlight-selected
										@map-click="mapClick"></county-map>
						</div>

						<page-loading :loading="page.data.loading"></page-loading>
						<div v-if="!page.data.loading && selectedCounty.id !== null" class="mb-3">
							<div class="mb-1">
								Average Precipitation 1950-2008 (in)
							</div>

							<table class="table table-bordered table-sm">
								<tr class="bg-light">
									<th>Jan</th>
									<th>Feb</th>
									<th>Mar</th>
									<th>Apr</th>
								</tr>
								<tr>
									<td>{{selectedCounty.monthlyPcp[0] | number(1)}}</td>
									<td>{{selectedCounty.monthlyPcp[1] | number(1)}}</td>
									<td>{{selectedCounty.monthlyPcp[2] | number(1)}}</td>
									<td>{{selectedCounty.monthlyPcp[3] | number(1)}}</td>
								</tr>
								<tr class="bg-light">
									<th>May</th>
									<th>Jun</th>
									<th>Jul</th>
									<th>Aug</th>
								</tr>
								<tr>
									<td>{{selectedCounty.monthlyPcp[4] | number(1)}}</td>
									<td>{{selectedCounty.monthlyPcp[5] | number(1)}}</td>
									<td>{{selectedCounty.monthlyPcp[6] | number(1)}}</td>
									<td>{{selectedCounty.monthlyPcp[7] | number(1)}}</td>
								</tr>
								<tr class="bg-light">
									<th>Sep</th>
									<th>Oct</th>
									<th>Nov</th>
									<th>Dec</th>
								</tr>
								<tr>
									<td>{{selectedCounty.monthlyPcp[8] | number(1)}}</td>
									<td>{{selectedCounty.monthlyPcp[9] | number(1)}}</td>
									<td>{{selectedCounty.monthlyPcp[10] | number(1)}}</td>
									<td>{{selectedCounty.monthlyPcp[11] | number(1)}}</td>
								</tr>
								<tr class="bg-light">
									<th colspan="4">Annual</th>
								</tr>
								<tr>
									<td colspan="4">{{selectedCounty.annualPcp | number(1)}}</td>
								</tr>
							</table>

							<div>
								Station Elevation: {{selectedCounty.elevation | number(1)}} ft
							</div>
						</div>
					</b-col>
				</b-row>				

				<fixed-action-bar :cols-lg="12" :offset-lg="0" :cols="12" :offset="0" fixed-width>
					<b-button type="submit" class="mr-2" :disabled="!$v.$anyDirty" variant="success">
						<font-awesome-icon v-if="page.saving" :icon="['fas', 'spinner']" spin />
						Save
					</b-button>
					<parent-button :back-to="isUpdate? 'plan' : 'plans'" to="."></parent-button>
				</fixed-action-bar>
			</b-form>
		</div>
	</auth-container>
</template>

<script>
	import { required, helpers, decimal } from 'vuelidate/lib/validators';
	import CountyMap from '@/components/CountyMap';
	import moment from 'moment';

	const planNumberFormat = helpers.regex('planNumberFormat', /[0-9]{3}-[0-9]{2}-[0-9]{3,}/)

	export default {
		name: 'PlanForm',
		components: {
			CountyMap
		},
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			id: {
				type: String,
				default: null
			}
		},
		data() {
			return {
				page: {
					id: this.id,
					errors: [],
					loading: false,
					showLogin: false,
					saving: false,
					running: false,
					saveErrors: [],
					delete: {
						show: false,
						errors: [],
						saving: false
					},
					data: {
						loading: false,
						errors: []
					},
					locationTabIndex: 0
				},
				form: {
					producer: null,
					planNumber: null,
					county: null,

					//Optional Farm Data
					farmDate: moment().format('YYYY-MM-DD'),
					farmEvaluator: null,
					farmFunding319: false,
					farmFunding503: false,
					farmFundingOther: null,
					farmDescription: null,
					huc12: null,
					latitude: 0,
					longitude: 0
				},
				locationFormat: {
					lat: {
						degrees: 0,
						minutes: 0,
						seconds: 0
					},
					lon: {
						degrees: 0,
						minutes: 0,
						seconds: 0
					}
				},
				status: {
					isRunning: false,
					hasError: false,
					hasRun: false,
					lastRunDate: null,
					runTime: null,
					zipFile: null,
					progress: {
						percent: 0,
						message: 'Please wait...'
					},
					exceptionMessage: null
				},
				options: {
					counties: [],
					planNumbers: [],
					producers: []
				},
				selectedCounty: {
					id: null,
					name: null,
					state: null,
					fips: null,
					latitude: null,
					longitude: null,
					elevation: null,
					annualPcp: null,
					monthlyPcp: []
				},
				initialCounty: null,
				isPlanSet: this.isUpdate
			}
		},
		validations: {
			form: {
				planNumber: { required, planNumberFormat },
				producer: { required },
				county: { required },
				farmDate: { required },
				farmEvaluator: {},
				farmFunding319: {},
				farmFunding503: {},
				farmFundingOther: {},
				farmDescription: {},
				huc12: {},
				latitude: { required, decimal },
				longitude: { required, decimal }
			},
			locationFormat: {
				lat: {
					degrees: { required, decimal },
					minutes: { required, decimal },
					seconds: { required, decimal }
				},
				lon: {
					degrees: { required, decimal },
					minutes: { required, decimal },
					seconds: { required, decimal }
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			reRenderMap() {
				this.$nextTick(() => {
					this.log(this.$refs);
					this.$refs.countyMap.renderMap();
				});
			},
			async get() {
				let url = this.isUpdate ? `plans/${this.id}` : `plans/options`;
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(url, this.getTokenHeader());
					this.log(response.data);

					if (this.isUpdate) {
						this.form = response.data.plan;
						this.options = response.data.options;
						this.selectedCounty = response.data.selectedCounty;
						this.initialCounty = this.form.county;
						this.status = response.data.status;

						if (!this.isNullOrEmpty(this.form.farmDate)) {
							this.form.farmDate = moment(this.form.farmDate).format('YYYY-MM-DD');
						}

						this.getLocation(true);
					} else {
						this.options = response.data;
					}
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;
				this.$v.form.$touch();

				if (this.form.latitude === 0) {
					this.getLocation(false);
				}

				if (this.$v.$invalid) {
					this.page.saveErrors.push('Please fix the errors below and try again.');
				} else {
					this.log(this.form);
					try {
						if (!this.isPlanSet) {
							const response = await this.$http.post('plans', this.form, this.getTokenHeader());
							this.page.id = response.data;
							this.$router.push({ name: 'PlanView', params: { id: this.page.id.toString() } }).catch(err => { this.log(err); });
						} else {
							await this.$http.put(`plans/${this.page.id}`, this.form, this.getTokenHeader());
						}

						this.$bvToast.show('changes-saved');
						this.isPlanSet = true;

						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			async mapClick(selectedCounties) {
				this.log(selectedCounties);
				if (selectedCounties.length > 0) {
					await this.switchCountyName(selectedCounties[0]);
				}
			},
			async switchCounty() {
				await this.switchCountyName(this.form.county);
			},
			async switchCountyName(selectedCounty) {
				this.page.data.errors = [];
				this.page.data.loading = true;
				this.form.county = selectedCounty;

				try {
					const response = await this.$http.get(`plans/county/${selectedCounty}`, this.getTokenHeader());
					this.log(response.data);
					this.selectedCounty = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.data.errors = this.logError(error);
				}

				this.page.data.loading = false;
			},
			planNumberFormatter(value) {
				if (value.length > 0 && value[value.length - 1] !== '-') {
					if (value.length === 4 && value[3] !== '-') return value.substring(0, 3) + '-' + value[3];
					if (value.length === 7 && value[6] !== '-') return value.substring(0, 6) + '-' + value[6];
				}
				return value;
			},
			getLocation(fromDecimal) {
				if (fromDecimal) {
					/*let deg = Math.floor(this.form.latitude);
					let min = (this.form.latitude % 1) * 60;
					let sec = Math.ceil((min % 1) * 60);
					this.locationFormat.lat.degrees = deg;
					this.locationFormat.lat.minutes = Math.floor(min);
					this.locationFormat.lat.seconds = sec;

					deg = Math.floor(this.form.longitude * -1);
					min = ((this.form.longitude * -1) % 1) * 60;
					sec = Math.ceil((min % 1) * 60);
					this.locationFormat.lon.degrees = deg;
					this.locationFormat.lon.minutes = Math.floor(min);
					this.locationFormat.lon.seconds = sec;*/
					let lat = this.convertDdToDms(this.form.latitude);
					this.locationFormat.lat.degrees = lat.deg;
					this.locationFormat.lat.minutes = lat.min;
					this.locationFormat.lat.seconds = lat.sec;

					let lng = this.convertDdToDms(this.form.longitude);
					this.locationFormat.lon.degrees = lng.deg;
					this.locationFormat.lon.minutes = lng.min;
					this.locationFormat.lon.seconds = lng.sec;
				} else {
					let lat = this.locationFormat.lat;
					let lon = this.locationFormat.lon;

					this.form.latitude = lat.degrees + (lat.minutes / 60) + (lat.seconds / 3600);
					this.form.longitude = (lon.degrees + (lon.minutes / 60) + (lon.seconds / 3600)) * -1;
				}
			}
		}
	}
</script>
