<template>
	<div>
		<auth-container :page="page" require-admin>
			<b-container fluid class="p-0">
				<b-row no-gutters>
					<b-col md="3" lg="2" order="2" order-md="1" class="fixed-height bg-light">
						<div class="px-3 pt-4">
							<b-nav vertical class="dash-items-nav nav-lg">
								<b-nav-item to="/admin/plans">
									<font-awesome-icon :icon="['fas', 'project-diagram']" fixed-width class="mr-3" /> Plans
								</b-nav-item>
								<b-nav-item to="/admin/users">
									<font-awesome-icon :icon="['fas', 'user-friends']" fixed-width class="mr-3" /> Users
								</b-nav-item>
								<b-nav-item to="/admin/errors">
									<font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width class="mr-3" /> Error Reports
								</b-nav-item>
								<b-nav-item :href="`/jobs?access_token=${localStorageToken}`" target="_blank">
									<font-awesome-icon :icon="['fas', 'layer-group']" fixed-width class="mr-3" /> Job queue
								</b-nav-item>
							</b-nav>
						</div>
					</b-col>
					<b-col md="9" lg="10" order="1" order-md="2" class="fixed-height shadow-sm no-shadow-sm" style="z-index:500">
						<b-breadcrumb :items="breadCrumbs" class="bg-white px-md-4 mb-0"></b-breadcrumb>
						<div class="container-fluid px-md-4 pt-2 pb-4">
							<div v-if="$route.name === 'AdminDashboard'">
								<div class="text-muted mb-3 border-bottom pb-2">
									<small>
										<font-awesome-icon :icon="['far', 'clock']" class="mr-1" />
										The statistics on this page were computed at
										{{ data.computedAt | date }}.
										<a href="#" @click.prevent="get">Refresh now.</a>
									</small>
								</div>

								<div class="mb-4">
									<highcharts :options="statisticsTimeChart"></highcharts>
								</div>

								<h2 class="h4 text-center">Totals</h2>
								<hr class="mt-2 mb-3" />
								<b-row align-h="center">
									<b-col cols="4" lg="3">
										<div class="stat-box bg-dark text-white mb-2">
											<div class="title">Users</div>
											<div class="value">{{ data.numUsers |  number(0) }}</div>
										</div>
									</b-col>
									<b-col cols="4" lg="3">
										<div class="stat-box bg-dark text-white mb-2">
											<div class="title">Plans</div>
											<div class="value">{{ data.numPlans |  number(0) }}</div>
										</div>
									</b-col>
									<b-col cols="4" lg="3">
										<div class="stat-box bg-dark text-white mb-2">
											<div class="title">Running Now</div>
											<div class="value">
												{{ data.numPlansRunning |  number(0) }}
												<span :title="`As of ${toDate(data.computedAt)}. Click to refresh.`">
													<a class="mute" href="#" @click.prevent="get">
														<font-awesome-icon :icon="['far', 'clock']" @click="get" />
													</a>
												</span>
											</div>
										</div>
									</b-col>
								</b-row>

								<h2 class="h4 text-center mt-4">Averages</h2>
								<hr class="mt-2 mb-3" />
								<b-row align-h="center">
									<b-col cols="6" lg="3">
										<div class="stat-box bg-info text-white mb-2">
											<div class="title">Plans / User</div>
											<div class="value">{{ data.avgPlansPerUser |  number(1) }}</div>
										</div>
									</b-col>
									<b-col cols="6" lg="3">
										<div class="stat-box bg-info text-white mb-2">
											<div class="title">Plan Run Time</div>
											<div class="value">
												{{ data.avgPlanRunTime |  number(1) }}
												<span title="minutes">min</span>
											</div>
										</div>
									</b-col>
								</b-row>

								<success-alert ref="savedAlert" text="Import complete." />
								<save-button v-if="false" :saving="page.saving" type="button" @click.native="importData" text="Import Data" />
							</div>
							<router-view></router-view>
						</div>
					</b-col>
				</b-row>
			</b-container>
		</auth-container>
	</div>
</template>

<script>
	export default {
		name: 'AdminDashboard',
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saving: false,
					saveErrors: []
				},
				data: {
					statisticsTimeChart: {}
				}
			}
		},
		async created() {
			await this.get();
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get('admin/dashboard', this.getTokenHeader());
					this.log(response.data);
					this.data = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			/*async importData() {
				this.page.saveErrors = [];
				this.page.saving = true;

				try {
					const response = await this.$http.post('import/data', {}, this.getTokenHeader());
					this.log(response);
					this.$refs.savedAlert.startAlert();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.saveErrors = this.logError(error);
				}

				this.page.saving = false;
			}*/
		},
		computed: {
			breadCrumbs() {
				var crumbs = [];
				for (let item of this.$route.matched) {
					let name = item.meta.title ? item.meta.title : item.name;

					crumbs.push({
						text: name,
						to: { name: item.name }
					});
				}
				return crumbs;
			},
			statisticsTimeChart() {
				return {
					chart: { type: 'bar' },
					plotOptions: { bar: { dataLabels: { enabled: true } } },
					title: { text: this.data.statisticsTimeChart.title },
					xAxis: { categories: this.data.statisticsTimeChart.xAxisCategories },
					yAxis: { title: { text: '' } },
					series: this.data.statisticsTimeChart.series
				};
			}
		}
	}
</script>