<template>
	<plan-form is-update :id="id"></plan-form>
</template>

<script>
	import PlanForm from '@/views/plans/PlanForm';

	export default {
		name: 'PlanEdit',
		components: {
			PlanForm
		},
		data() {
			return {
				id: this.$route.params.id
			}
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			get() {
				this.id = this.$route.params.id;

				if (this.$parent.status.isRunning || this.$parent.status.hasError) {
					this.$router.push({ name: 'PlanView', params: { id: this.id.toString() } }).catch(err => { this.log(err); });
				}
			}
		}
	}
</script>