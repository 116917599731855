<template>
	<footer>
		<b-row align-h="center" align-v="center">
			<b-col cols="4" md="2">
				<img :src="`${publicPath}images/logos/tsswcb-logo.svg`" alt="TSSWCB Logo" class="img-fluid">
			</b-col>
			<b-col cols="4" md="2">
				<img :src="`${publicPath}images/logos/agrilife-research.png`" alt="Texas A&amp;M AgriLife Research Logo" class="img-fluid">
			</b-col>
			<b-col cols="6" md="3">
				<img :src="`${publicPath}images/logos/tamu.svg`" alt="TAMU Logo" class="img-fluid">
			</b-col>
			<b-col cols="6" md="3">
				<img :src="`${publicPath}images/logos/usda-ars.png`" alt="USDA-ARS Logo" class="img-fluid">
			</b-col>
		</b-row>

		<div class="text-center text-muted mt-4">
			<p class="mb-2">
				This project is supported by the Texas State Soil &amp; Water Conservation Board, Texas A&amp;M AgriLife Research, and the USDA Agricultural Research Service.
			</p>
			<p>
				For assistance with account settings and system errors, please contact <a :href="`mailto:${contactEmail}`">{{contactEmail}}</a>.
			</p>
		</div>
	</footer>
</template>

<script>
	export default {
		name: 'FooterData'
	}
</script>
